import React from "react";

import moment from "moment";
import { Avatar, Tag } from "rsuite";
import { useWindowSize } from "util/getWindowSize";

const LatestActivityCard = ({ mentor, mentees, user, date, category }) => {
  const { fullname: mentorName, profile_photo_url: mentorPhoto } = mentor || {};
  const { fullname: userName, profile_photo_url: userPhoto } = user || {};

  const windowSize = useWindowSize();
  const hdDesktopScreen = windowSize === "3xl";

  // TODO: Sort out which category there is. Right now there is bookings and reflections
  const categoryTag = (category) => {
    const categoryTagMap = {
      created: {
        color: "blue",
        text: "New Booking",
      },
      confirmed: {
        color: "cyan",
        text: "Session Confirmed",
      },
      completed: {
        color: "green",
        text: "Session Completed",
      },
      canceled: {
        color: "red",
        text: "Session Cancelled",
      },
      reviewed: {
        color: "orange",
        text: "New Reflection",
      },
      // FUT-1646: Temporarily changed to submitted. Original plan was "survey"
      submitted: {
        color: "violet",
        text: "Survey Submitted",
      },
    };

    return (
      <Tag color={categoryTagMap[category]?.color || ""}>
        {categoryTagMap[category]?.text || ""}
      </Tag>
    );
  };

  const formatDate = (activityDate) => {
    const date = moment(activityDate);
    const now = moment();

    if (date.isSame(now, "day")) {
      // If the date is today
      return `Today ${date.format("h:mm A")}`;
    } else if (date.isSame(now.clone().subtract(1, "day"), "day")) {
      // If the date is yesterday
      return `Yesterday ${date.format("h:mm A")}`;
    } else {
      // For other dates
      return date.format("DD MMM YYYY h:mm A");
    }
  };

  return (
    <div className="tw-col-span-1 tw-border tw-border-solid tw-border-[#E5E5EA] tw-rounded-lg tw-p-4 tw-flex tw-flex-col tw-mb-2">
      <div>{categoryTag(category)}</div>

      {category === "submitted" && (
        <div className="tw-flex tw-flex-row tw-gap-2 tw-my-4">
          <div className="tw-w-1/2">
            <div className="tw-flex tw-gap-2">
              <Avatar
                src={userPhoto || ""}
                alt={userName?.charAt(0) || ""}
                className="tw-shrink-0"
                style={{ background: "#7B1FA2" }}
                size={hdDesktopScreen ? "md" : "sm"}
              />
              <p className={hdDesktopScreen ? "tw-text-sm" : "tw-text-xs"}>
                {userName || ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {category !== "submitted" && mentor && mentees && (
        <div className="tw-flex tw-flex-row tw-gap-2 tw-my-4">
          <div className="tw-w-1/2">
            <p className="tw-text-sm tw-font-bold tw-mb-2">Mentor</p>
            <div className="tw-flex tw-gap-2">
              <Avatar
                src={mentorPhoto || ""}
                alt={mentorName?.charAt(0) || ""}
                className="tw-shrink-0"
                style={{ background: "#7B1FA2" }}
                size={hdDesktopScreen ? "md" : "sm"}
              />
              <p className={hdDesktopScreen ? "tw-text-sm" : "tw-text-xs"}>
                {mentorName || ""}
              </p>
            </div>
          </div>

          <div className="tw-w-1/2">
            <p className="tw-text-sm tw-font-bold tw-mb-2">Mentee</p>
            {mentees.map((mentee, idx) => (
              <div key={idx} className="tw-flex tw-gap-2 tw-mb-2">
                <Avatar
                  src={mentee?.profile_photo_url || ""}
                  alt={mentee?.fullname?.charAt(0) || ""}
                  className="tw-shrink-0"
                  size={hdDesktopScreen ? "md" : "sm"}
                />
                <p className={hdDesktopScreen ? "tw-text-sm" : "tw-text-xs"}>
                  {mentee?.fullname || ""}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="tw-flex tw-justify-between tw-items-center tw-mt-auto ">
        <span className="tw-text-sm">{formatDate(date)}</span>
        {/* TODO: FUT-1611: Remove until we have a view for details button */}
        {/* <Button appearance="link" className="tw-p-0">
          View Details
        </Button> */}
      </div>
    </div>
  );
};

export default LatestActivityCard;
