// React
import React from "react";

// UI
import { Avatar, Button } from "rsuite";

const MenteesCards = ({ mentees }) => {
  return (
    <div className="tw-mt-8">
      <h6>Mentees</h6>
      <div className="tw-grid tw-gap-6 tw-grid-cols-12 xs:tw-grid-cols-4 sm:tw-grid-cols-8">
        {mentees?.map((mentee) => {
          return (
            <div
              key={mentee.id}
              className="tw-col-span-4 tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-p-4"
            >
              <div className="tw-grid tw-grid-cols-12">
                <div className="tw-col-span-3">
                  <a href={mentee.program_member_path}>
                    <Avatar
                      size="lg"
                      circle
                      src={mentee.profile_photo}
                      alt={mentee.image_alt_name}
                    />
                  </a>
                </div>
                <div className="tw-col-span-9">
                  <h6 className="tw-truncate">{mentee.full_name}</h6>
                  <p className="tw-text-sm">{mentee.profession}</p>
                </div>
              </div>
              <div className="tw-flex tw-justify-center tw-mt-3">
                <Button
                  className="tw-mr-3"
                  appearance="primary"
                  href={mentee.progress_path}
                >
                  View Progress
                </Button>
                <Button appearance="ghost" href={mentee.chat_link}>
                  Message
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenteesCards;
