import React from "react";
import { Button, Panel } from "rsuite";
import ConnectionsDonutChart from "../charts/ConnectionsDonutChart";

const ConnectionsPanel = ({
  bookings,
  chat,
  eventSignUps,
  totalSurveySent,
}) => {
  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Connections</h6>
        <Button appearance="ghost">View more</Button>
      </div>

      <Panel bordered>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-flex tw-flex-col tw-items-center tw-mb-2">
            <span className="tw-text-3xl tw-font-bold">8</span>
            <span className="tw-text-sm">Avg. no of connections</span>
          </div>

          <div className="tw-relative">
            <ConnectionsDonutChart
              bookings={bookings}
              chat={chat}
              eventSignUps={eventSignUps}
            />

            <div
              className="tw-flex tw-justify-center tw-flex-col tw-text-center"
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <span className="tw-font-bold">{totalSurveySent}</span>
              <span className="tw-text-sm">
                Total <br /> Connections
              </span>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default ConnectionsPanel;
