import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const UserDonutChart = ({ totalMentees, totalMentors, totalAdmins }) => {
  const series = [totalMentees || 0, totalMentors || 0, totalAdmins || 0];
  const options: ApexOptions = {
    labels: ["Mentee", "Mentor", "Admin"],
    legend: {
      show: true,
      position: "bottom",
      markers: {
        radius: 6,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (_, { seriesIndex, w }) => {
        return w.config.series[seriesIndex];
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="donut" height={300} />;
};

export default UserDonutChart;
