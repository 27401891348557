import React, { useState } from "react";
import { Avatar, Button, Divider, Nav, Panel } from "rsuite";
import UserSatisfactionScorePanel from "../components/UserSatisfactionScorePanel";
import LatestReflectionsPanel from "../components/LatestReflectionsPanel";
import GoalsInsightsPanel from "../components/GoalsInsightsPanel";
import ChallengesInsightsPanel from "../components/ChallengesInsightsPanel";
import SkillsInsightsPanel from "../components/SkillsInsightsPanel";
import IndustriesInsightsPanel from "../components/IndustriesInsightsPanel";

const UserInsights = () => {
  const [activeKey, setActiveKey] = useState("mentee");

  return (
    <>
      {/* Title and Export Button Action */}
      <div className="tw-my-10">
        <h6 className="tw-text-xl">User Insights</h6>
        <p className="tw-text-sm">
          Information on your user satisfaction, interest and behaviours.
        </p>
      </div>

      {/* Nav */}
      <div className="tw-w-1/4 tw-my-10">
        <Nav
          appearance="subtle"
          justified
          onSelect={setActiveKey}
          activeKey={activeKey}
        >
          <Nav.Item eventKey="mentee">Mentee</Nav.Item>
          <Nav.Item eventKey="mentor">Mentor</Nav.Item>
        </Nav>
      </div>

      <div className="tw-my-10 tw-grid tw-grid-cols-5 tw-gap-4">
        <UserSatisfactionScorePanel userType={activeKey} />

        {/* Latest Reflections */}
        <div className="tw-col-span-3">
          <LatestReflectionsPanel userType={activeKey} />
        </div>
      </div>

      {/* User Insights */}
      {/* FUT-1654 */}
      <div className="tw-my-10">
        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          {/* Goals */}
          <div>
            <GoalsInsightsPanel userType={activeKey} />
          </div>

          {/* Industries */}
          <div>
            <IndustriesInsightsPanel userType={activeKey} />
          </div>
        </div>

        {/* FUT-1654 - Commented out until customisation program sign up questions are available. */}
        {/* <div className="tw-my-10">
          <div className="tw-grid tw-grid-cols-2 tw-gap-4">
            <div>
              <SkillsInsightsPanel userType={activeKey} />
            </div>

            <div>
              <ChallengesInsightsPanel userType={activeKey} />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default UserInsights;
