import React from "react";
import { Button, Panel } from "rsuite";
import LearningProgressDonutChart from "../charts/LearningProgressDonutChart";
import moment from "moment";

const LearningProgressCard = ({ module, index }) => {
  const { title, start_time, end_time, data } = module || {};

  const formatStartTime = moment(start_time).format("DD MMM YYYY");
  const formatEndTime = moment(end_time).format("DD MMM YYYY");

  return (
    <div className="tw-my-10">
      <div key={index}>
        <h6>{title}</h6>
        <p className="tw-text-sm">
          {formatStartTime} - {formatEndTime}
        </p>

        <Panel bordered>
          <div className="tw-grid tw-grid-cols-3 tw-gap-4">
            {/* Module Pie Charts */}

            {data?.map(
              ({
                chart,
                name: chartTitle,
                percentage: chartPercentage,
                total: chartTotal,
              }) => (
                <div className="tw-col-span-1 tw-flex tw-items-center">
                  <div className="tw-relative">
                    <LearningProgressDonutChart data={chart} />
                    <span
                      className="tw-text-2xl tw-font-bold"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {chartPercentage}%
                    </span>
                  </div>
                  <div className="tw-flex tw-flex-col ">
                    <span className="tw-text-3xl tw-font-bold">
                      {chartTotal}
                    </span>
                    <span>{chartTitle}</span>
                    {/* TODO: FUT-1611: View details disabled until view has been rectified */}
                    {/* <Button appearance="link" className="tw-py-2 tw-px-0">
                      View details
                    </Button> */}
                  </div>
                </div>
              )
            )}
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default LearningProgressCard;
