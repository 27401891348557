// React
import React, { useEffect, useState } from "react";

// Futurelab
import { usePortal } from "../../../contexts/portal";
import { PortalApi } from "../../../util/portal_api";
import RatingDetailsDrawer from "./RatingDetailsDrawer";
import SearchInput from "./components/SearchInput";
import FilterButton from "./components/FilterButton";
// import { CheckCell } from "./components/CheckCell";
import SendFeedbackNotificationButton from "./components/SendFeedbackNotificationButton";
import { OneOnOnePairing } from "./table/OneOnOnePairing";
import { GroupPairing } from "./table/GroupPairing";
import { OverviewPairing } from "./table/OverviewPairing";
import DownloadButton from "./components/DownloadButton";

// UI
import { FlexboxGrid, Message, toaster, Nav } from "rsuite";

// 3rd Party
import _ from "lodash";
import moment from "moment";

const SessionManagement = () => {
  const { program, enterprise } = usePortal();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    personalPairing: [],
    groupPairing: [],
    overviewPairing: [],
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [mentee, setMentee] = useState({});
  const [mentor, setMentor] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [healthFilter, setHealthFilter] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [drawerType, setDrawerType] = useState("");

  let fileName = `${program?.name} ${moment().format("DD-MM-YYYY")}.csv`;

  useEffect(() => {
    setLoading(true);
    if (program) {
      PortalApi({
        method: "get",
        path: `/sessions_management/main`,
        options: { program_id: program.id },
        success: ({ data }) => {
          setLoading(false);
          const oneOnOneOrderByMostHealthy = _.orderBy(
            data.program_pairs,
            [(session) => session.pairing_health_score],
            ["asc"]
          );
          const groupOrderByMostHealthy = _.orderBy(
            data.program_group_pairs,
            [(session) => session.pairing_health_score],
            ["asc"]
          );
          setData((prevState) => ({
            ...prevState,
            personalPairing: oneOnOneOrderByMostHealthy,
            groupPairing: groupOrderByMostHealthy,
            overviewPairing: data.program_mentees,
          }));
        },
        error: (err) => {
          setLoading(false);
          const { error } = err.response.data;
          toaster.push(
            <Message showIcon type="error">
              {error}
            </Message>
          );
        },
      });
    }
    return () => {
      setLoading(false);
      setData((prevState) => ({
        ...prevState,
        personalPairing: [],
        groupPairing: [],
        overviewPairing: [],
      }));
    };
  }, [program]);

  // [Start] - Algo for checked cell. Couldn't decouple this code, Rsuitejs requires HeaderCell to be in the same component.
  // Consider to refactor in the futre.
  // 13/04/2013: Checkboxes has been put to KIV until further notice.

  // let checked = false;
  // let indeterminate = false;

  // if (checkedKeys.length === data.length) {
  //   checked = true;
  // } else if (checkedKeys.length === 0) {
  //   checked = false;
  // } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
  //   indeterminate = true;
  // }

  // const handleCheckAll = (value, checked) => {
  //   const keys = checked ? data.map((item) => item.id) : [];
  //   setCheckedKeys(keys);
  // };

  // const handleCheck = (value, checked) => {
  //   const keys = checked
  //     ? [...checkedKeys, value]
  //     : checkedKeys.filter((item) => item !== value);
  //   setCheckedKeys(keys);
  // };

  // [End] - Algo for checked cell.

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleDrawer = (id, mentee, mentor, type) => {
    setOpenDrawer(true);
    setGroupId(id);
    setMentee(mentee);
    setMentor(mentor);
    setDrawerType(type);
  };

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item colspan={24} >
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-w-1/2">
            <h1 className="tw-text-xl">Pairing Health</h1>

            {/* {program.start_date && (
              <p className="montserrat">
                `{program?.start_date} - {program?.end_date}`
              </p>
            )} */}
          </div>

          <div className="tw-w-1/2 tw-flex tw-justify-end">
            <SearchInput state={setSearchKeyword} />
            <FilterButton value={healthFilter} state={setHealthFilter} />
            <DownloadButton
              data={data}
              fileName={fileName}
              activeKey={activeKey}
            />
            <span className={activeKey === "2" ? "" : "tw-hidden"}>
              <SendFeedbackNotificationButton
                programId={program?.id}
                feedbackTime={program?.feedback_reminders?.sent_at}
              />
            </span>
          </div>
        </div>
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          className="tw-my-6"
          justified
        >
          <Nav.Item eventKey="1" className="tw-justify-center">
            {`Overview (${data.overviewPairing.length})`}
          </Nav.Item>
          <Nav.Item eventKey="2" className="tw-justify-center">
            {`One on One Pairing (${data.personalPairing.length})`}
          </Nav.Item>
          <Nav.Item eventKey="3" className="tw-justify-center">
            {`Group Pairing (${data.groupPairing.length})`}
          </Nav.Item>
        </Nav>

        {activeKey === "1" && (
          <OverviewPairing
            data={data}
            sortColumn={sortColumn}
            healthFilter={healthFilter}
            searchKeyword={searchKeyword}
            sortType={sortType}
            program={program}
            loading={loading}
            handleSortColumn={handleSortColumn}
          />
        )}

        {activeKey === "2" && (
          <OneOnOnePairing
            data={data}
            sortColumn={sortColumn}
            healthFilter={healthFilter}
            searchKeyword={searchKeyword}
            sortType={sortType}
            program={program}
            loading={loading}
            handleSortColumn={handleSortColumn}
            handleDrawer={handleDrawer}
          />
        )}

        {activeKey === "3" && (
          <GroupPairing
            data={data}
            sortColumn={sortColumn}
            healthFilter={healthFilter}
            searchKeyword={searchKeyword}
            sortType={sortType}
            program={program}
            loading={loading}
            handleSortColumn={handleSortColumn}
            handleDrawer={handleDrawer}
            enterpriseSlug={enterprise?.slug}
            programSlug={program?.slug}
          />
        )}
      </FlexboxGrid.Item>

      {/* Section for Drawer */}
      <RatingDetailsDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        groupId={groupId}
        mentee={mentee}
        mentor={mentor}
        type={drawerType}
      />
    </FlexboxGrid>
  );
};

export default SessionManagement;
