import React from "react";
import { usePortal } from "contexts/portal";
import { useChallengesSummary } from "../hooks/usePortalAnalytics";
import InsightsPanel from "./InsightsPanel";

const ChallengesInsightsPanel = ({ userType }) => {
  const { program } = usePortal();
  const { data, isLoading } = useChallengesSummary(program.id, userType);

  return (
    <InsightsPanel
      title="Challenges"
      data={data}
      isLoading={isLoading}
      dataKeys={{
        name: "challenge_name",
        totalUsers: "users_count",
        percentage: "percentage",
      }}
    />
  );
};

export default ChallengesInsightsPanel;
