import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import ApexChart from "./ApexChart";

const SessionCompletionBarChart = ({ data, isLoading }) => {
  const { booked, conducted, reflected } = data || {};

  const series = [
    {
      data: [
        {
          x: "Booked",
          y: booked || 0,
        },
        {
          x: "Conducted",
          y: conducted || 0,
        },
        {
          x: "Reflected",
          y: reflected || 0,
        },
      ],
    },
  ];

  const options: ApexOptions = {
    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 6,
        dataLabels: {
          position: "top",
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      markers: {
        radius: 6,
      },
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const point = w.config.series[seriesIndex].data[dataPointIndex];
        return `<div style="
                  padding: 8px;
                  border-radius: 4px;
                  background: #fff;
                  border-radius: 4px;
                  font-size: 12px;
                  text-align: center;
                ">
                  ${point.x}: <strong>${point.y}</strong>
                </div>`;
      },
    },
  };

  return (
    <ApexChart
      options={options}
      isLoading={isLoading}
      series={series}
      type="bar"
      height={"350"}
    />
  );
};

export default SessionCompletionBarChart;
