// React
import React from "react";

// FutureLab
import { formatDateTime } from "util/dateTimeFormatter";

// UI
import { FaRegCalendarCheck, FaRegClock } from "react-icons/fa6";
import { Avatar, Button } from "rsuite";

const BookingCards = ({ bookings }) => {
  return (
    <div>
      <h6>Upcoming Sessions</h6>
      <div className="tw-grid tw-gap-6 tw-grid-cols-12 xs:tw-grid-cols-4 sm:tw-grid-cols-8">
        {[bookings]?.map((booking) => {
          return (
            <div className="tw-relative tw-col-span-4 tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-py-4 tw-px-3">
              <div className="tw-grid tw-grid-cols-12 tw-gap-4 tw-mb-2">
                <div className="tw-col-span-3 tw-place-self-center">
                  <a href={booking.profile}>
                    <Avatar size="lg" circle src={booking.photo} />
                  </a>
                </div>
                <div className="tw-col-span-9">
                  <h6 className="tw-truncate">{booking.name}</h6>
                  <span className="tw-gap-2 tw-text-sm tw-inline-flex tw-items-center">
                    <FaRegCalendarCheck size={16} />
                    {`${formatDateTime(booking.date, "Do MMMM YYYY")}`}
                  </span>
                  <span className="tw-gap-2 tw-text-sm tw-inline-flex tw-items-center">
                    <FaRegClock size={16} />
                    {`${booking.time} - ${booking.ending_time}`}
                  </span>
                </div>
              </div>
              <div className="tw-text-right">
                {booking.status === "Confirmed" &&
                booking.session_type === "Video Call" ? (
                  <Button href={booking.contact_info} appearance="primary">
                    Start Session
                  </Button>
                ) : (
                  <Button href={booking.reschedule} appearance="link">
                    Reschedule
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookingCards;
