import { usePortal } from "contexts/portal";
import React from "react";
import { Button, Divider, Panel } from "rsuite";
import { useBookingSummary } from "../hooks/usePortalAnalytics";
import { useNavigate } from "react-router-dom";

const TotalSessionsPanel = () => {
  const { program } = usePortal();
  const { data, isLoading, isError, error } = useBookingSummary(program?.id);

  const {
    total_bookings_count: totalSessions,
    completion_percentage: avgSessionsConducted,
    rating_percentage: avgRatingsConducted,
  } = data || {};

  const navigate = useNavigate();

  if (isError) {
    console.error("Error fetching latest activities:", error);
    return <div>Error loading activities</div>;
  }

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Sessions</h6>
        <Button
          appearance="ghost"
          onClick={() => navigate("../bookings/bookings_list")}
        >
          View more
        </Button>
      </div>

      <Panel
        bordered
        className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-4"
      >
        <div className="tw-flex tw-flex-col tw-text-center">
          <span className="tw-text-3xl tw-font-bold">{totalSessions || 0}</span>
          <span className="tw-text-sm">Total Session Booked</span>
        </div>
        <Divider />
        <div className="tw-flex tw-flex-row tw-justify-center tw-w-full">
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-bold">
              {avgSessionsConducted || 0}%
            </span>
            <span className="tw-text-sm">
              Sessions
              <br />
              Conducted
            </span>
          </div>
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-bold">
              {avgRatingsConducted || 0}%
            </span>
            <span className="tw-text-sm">Reflections Submitted</span>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default TotalSessionsPanel;
