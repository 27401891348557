import { usePortal } from "contexts/portal";
import React from "react";
import { MdOutlineStar } from "react-icons/md";
import { Button, Panel } from "rsuite";
import { useLearningExpSummary } from "../hooks/usePortalAnalytics";
import { useNavigate } from "react-router-dom";

const LearningExperiencePanel = () => {
  const { program } = usePortal();
  const { data, isLoading, isError, error } = useLearningExpSummary(
    program?.id
  );

  const navigate = useNavigate();

  const {
    average_mentee_rating: avgMenteeRating,
    average_mentor_rating: avgMentorRating,
  } = data || {};

  if (isError) {
    console.error("Error fetching latest activities:", error);
    return <div>Error loading activities</div>;
  }

  return (
    <div className="tw-mt-6">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Learning Experience</h6>
        <Button appearance="ghost" onClick={() => navigate("../pairing-health")}>
          View more
        </Button>
      </div>

      <Panel bordered className="tw-py-4">
        <div className="tw-flex tw-justify-items-center">
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-bold tw-flex tw-items-center tw-justify-center	">
              <MdOutlineStar
                size={23}
                className="tw-mr-1"
                style={{ color: "#428AC9" }}
              />
              {avgMenteeRating || 0}
            </span>
            <span className="tw-text-sm">Avg. Mentee Rating</span>
          </div>
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-bold tw-flex tw-items-center tw-justify-center	">
              <MdOutlineStar
                size={23}
                className="tw-mr-1"
                style={{ color: "#428AC9" }}
              />
              {avgMentorRating || 0}
            </span>
            <span className="tw-text-sm">Avg. Mentor Rating</span>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default LearningExperiencePanel;
