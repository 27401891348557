import React, { useEffect, useState } from "react";
import { PortalApi } from "../../../../../../util/portal_api";
import { Col, DateRangePicker, FlexboxGrid, Panel } from "rsuite";
import SessionCompletionBarChart from "../charts/SessionCompletionBarChart";
import { usePortal } from "contexts/portal";
import { useSessionCompletion } from "../hooks/usePortalAnalytics";
import moment from "moment";

const SessionCompletionPanel = () => {
  const { program } = usePortal();
  const [dateRange, setDateRange] = useState(null);

  const { data, isLoading, isError, error } = useSessionCompletion(
    program?.id,
    dateRange
  );

  const {
    conducted_bookings: conducted,
    upcoming_bookings: booked,
    reflected_bookings: reflected,
  } = data || {};

  const handleSessionRange = (dateRanges) => {
    if (dateRanges && dateRanges.length === 2) {
      const [from_date, to_date] = dateRanges;

      setDateRange({ from_date: from_date, to_date: to_date });
    } else {
      setDateRange(null);
    }
  };

  return (
    <>
      <div>
        <h6>Session Completion</h6>
      </div>

      <Panel bordered className="tw-my-4">
        <div className="tw-flex tw-justify-end tw-mb-2">
          <DateRangePicker
            format={"dd MMM yyyy"}
            placeholder="Filter Date Range"
            className="tw-w-1/4"
            placement="autoVerticalEnd"
            onChange={(value) => handleSessionRange(value)}
          />
        </div>
        <div>
          <SessionCompletionBarChart
            data={{ booked, conducted, reflected }}
            isLoading={isLoading}
          />
        </div>
      </Panel>
    </>
  );
};

export default SessionCompletionPanel;
