import React from "react";
import { Button, Divider } from "rsuite";

import LearningExperiencePanel from "../components/LearningExperiencePanel";
import TotalSessionsPanel from "../components/TotalSessionsPanel";
import TotalUsersPanel from "../components/TotalUsersPanel";
import SurveyPanel from "../components/SurveyPanel";

import LatestActivityPanel from "../components/LatestActivityPanel";
import PairingPanel from "../components/PairingPanel";
import SessionsCompletionPanel from "../components/SessionsCompletionPanel";
import { useWindowSize } from "util/getWindowSize";

const ProgramOverview = ({ setActiveKey }) => {
  const windowSize = useWindowSize();
  const hdDesktopScreen = windowSize === "3xl";

  return (
    <>
      {/* Title and Export Button Action */}
      <div className="tw-my-10">
        <h6 className="tw-text-xl">Program Overview</h6>
        <p className="tw-text-sm">
          Your current program summary and performance.
        </p>
      </div>

      {/* Activity Section */}
      <div className="tw-my-10">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
          <h6>Latest Activities</h6>
          <Button appearance="ghost" onClick={() => setActiveKey("engagement")}>
            View more
          </Button>
        </div>

        <div
          className={`tw-grid tw-gap-4 ${
            hdDesktopScreen ? "tw-grid-cols-4" : "tw-grid-cols-3"
          }`}
        >
          <LatestActivityPanel />
        </div>
      </div>

      <Divider />

      {/* Pairing, Learning Experience, Sessions, Users Panel Section */}
      <div className="tw-my-10">
        <div className="tw-grid tw-grid-cols-3 tw-gap-4">
          <div className="tw-grid-cols-1">
            {/* Pairing Panel */}
            <PairingPanel />

            {/* Learning Experience Panel */}
            <LearningExperiencePanel />
          </div>

          <div className="tw-grid-cols-1">
            {/* Sessions Panel */}
            <TotalSessionsPanel />
          </div>

          <div className="tw-grid-cols-1">
            {/* Users Panel */}
            <TotalUsersPanel />
          </div>
        </div>
      </div>

      {/* Session Completion Panel */}
      <div className="tw-my-10">
        <SessionsCompletionPanel />
      </div>

      {/* Survey, Connections, Resources Panel */}
      <div className="tw-my-10">
        <div className="tw-grid tw-grid-cols-3 tw-gap-4">
          <div className="tw-grid-cols-1">
            <SurveyPanel />
          </div>

          {/* FUT-1611: Comment out until Chat-BE has been integrated to calculate connections score */}
          {/* <div className="tw-grid-cols-1">
            <ConnectionsPanel
              bookings={24}
              chat={64}
              eventSignUps={665}
              totalSurveySent={1343}
            />
          </div> */}

          {/* FUT-1611: Comment out until Resources Table (entity model) have included download/view count */}
          {/* <div className="tw-grid-cols-1">
            <ResourcesPanel />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProgramOverview;
