import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const LearningProgressDonutChart = ({ data }) => {
  const {
    series: dataSeries,
    labels: dataLabels,
    colors: dataColors,
  } = data || {};

  const series = dataSeries;

  const options: ApexOptions = {
    labels: dataLabels,
    legend: {
      show: false,
    },
    colors: dataColors,
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ seriesIndex, w }) {
        // Disable tooltip for "Others"
        if (w.globals.labels[seriesIndex] === "Others") {
          return null;
        }

        // Get the color for the current series
        const color = dataColors[seriesIndex];

        // Display the default tooltip with the inherited color
        return `
          <div style="
            padding: 8px;
            background-color: ${color};
            color: white;
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
          ">
            <strong>${w.globals.labels[seriesIndex]}</strong>: ${w.globals.series[seriesIndex]}
          </div>
        `;
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="donut" />;
};

export default LearningProgressDonutChart;
