import React from "react";
import UserDonutChart from "../charts/UserDonutChart";
import { Button, Panel } from "rsuite";
import { usePortal } from "contexts/portal";
import { useUserSummary } from "../hooks/usePortalAnalytics";
import { useNavigate } from "react-router-dom";

const TotalUsersPanel = () => {
  const { program } = usePortal();
  const { data, isLoading, isError, error } = useUserSummary(program?.id);

  const {
    total_users_count: totalUsers,
    admins_count: totalAdmins,
    mentors_count: totalMentors,
    mentees_count: totalMentees,
  } = data || {};

  const navigate = useNavigate();

  if (isError) {
    console.error("Error fetching latest activities:", error);
    return <div>Error loading activities</div>;
  }
  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Users</h6>
        <Button
          appearance="ghost"
          onClick={() => navigate("../user_management")}
        >
          View more
        </Button>
      </div>

      <Panel bordered>
        <div className="tw-relative tw-flex tw-items-center tw-justify-center">
          <UserDonutChart
            totalMentees={totalMentees}
            totalMentors={totalMentors}
            totalAdmins={totalAdmins}
          />
          <div
            className="tw-flex tw-justify-center tw-flex-col tw-text-center"
            style={{
              position: "absolute",
              top: "42%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span className="tw-font-bold">{totalUsers}</span>
            <span className="tw-text-sm">Total Users</span>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default TotalUsersPanel;
