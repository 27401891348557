import React, { useEffect, useState } from "react";

import { usePortal } from "contexts/portal";
import { PortalApi } from "util/portal_api";
import _ from "lodash";

import { Divider, Nav } from "rsuite";
import Toaster from "components/shared/Toaster";
import RatingDetailsDrawer from "components/portal/session_management/RatingDetailsDrawer";
import { OneOnOnePairing } from "components/portal/session_management/table/OneOnOnePairing";
import { GroupPairing } from "components/portal/session_management/table/GroupPairing";
import PairingHealthTrendsPanel from "../components/PairingHealthTrendsPanel";

const MentorMenteeHealth = () => {
  const [activeKey, setActiveKey] = useState("pair");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    personalPairing: [],
    groupPairing: [],
    overviewPairing: [],
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [mentee, setMentee] = useState({});
  const [mentor, setMentor] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const [drawerType, setDrawerType] = useState("");

  const { program } = usePortal();

  // TODO: Convert to react-query
  useEffect(() => {
    setLoading(true);
    if (program) {
      PortalApi({
        method: "get",
        path: `/sessions_management/main`,
        options: { program_id: program.id },
        success: ({ data }) => {
          setLoading(false);
          const oneOnOneOrderByMostHealthy = _.orderBy(
            data.program_pairs,
            [(session) => session.pairing_health_score],
            ["asc"]
          );
          const groupOrderByMostHealthy = _.orderBy(
            data.program_group_pairs,
            [(session) => session.pairing_health_score],
            ["asc"]
          );
          setData((prevState) => ({
            ...prevState,
            personalPairing: oneOnOneOrderByMostHealthy,
            groupPairing: groupOrderByMostHealthy,
            overviewPairing: data.program_mentees,
          }));
        },
        error: (err) => {
          setLoading(false);
          const { error } = err.response.data;
          Toaster({
            type: "error",
            msg: { error },
          });
        },
      });
    }
    return () => {
      setLoading(false);
      setData((prevState) => ({
        ...prevState,
        personalPairing: [],
        groupPairing: [],
        overviewPairing: [],
      }));
    };
  }, [program]);

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleDrawer = (id, mentee, mentor, type) => {
    setOpenDrawer(true);
    setGroupId(id);
    setMentee(mentee);
    setMentor(mentor);
    setDrawerType(type);
  };

  return (
    <>
      {/* Title and Export Button Action */}
      <div className="tw-my-10">
        <h6 className="tw-text-xl">Mentor-Mentee Health</h6>
        <p className="tw-text-sm">
          Health assessment of your mentor-mentee relationships.
        </p>
      </div>

      <div className="tw-my-10">
        <Nav
          activeKey={activeKey}
          justified
          onSelect={setActiveKey}
          appearance="subtle"
          className="tw-w-1/2 xl:tw-w-1/3"
        >
          <Nav.Item eventKey="pair">
            One on One Pairing {data && `(${data.personalPairing.length})`}
          </Nav.Item>
          <Nav.Item eventKey="group">
            Group Pairing {data && `(${data.groupPairing.length})`}
          </Nav.Item>
        </Nav>

        {/* Pairing Health Trends */}
        <div className="tw-my-10">
          <PairingHealthTrendsPanel type={activeKey} />
        </div>

        {/* Top 10 Pairings */}
        {/* TODO: Limit to 10 */}
        <div className="tw-my-10">
          <h6>Top 10 Pairings</h6>
          {activeKey === "pair" ? (
            <OneOnOnePairing
              data={data}
              sortColumn={sortColumn}
              healthFilter="great"
              searchKeyword={searchKeyword}
              sortType={sortType}
              program={program}
              loading={loading}
              handleSortColumn={handleSortColumn}
              handleDrawer={handleDrawer}
            />
          ) : (
            <GroupPairing
              data={data}
              sortColumn={sortColumn}
              healthFilter="great"
              searchKeyword={searchKeyword}
              sortType={sortType}
              program={program}
              loading={loading}
              handleSortColumn={handleSortColumn}
              handleDrawer={handleDrawer}
            />
          )}
        </div>

        <Divider />

        {/* At Risk Matches */}
        <div className="tw-my-10">
          <h6>At-Risk Matches</h6>
          {activeKey === "pair" ? (
            <OneOnOnePairing
              data={data}
              sortColumn={sortColumn}
              healthFilter="poor"
              searchKeyword={searchKeyword}
              sortType={sortType}
              program={program}
              loading={loading}
              handleSortColumn={handleSortColumn}
              handleDrawer={handleDrawer}
            />
          ) : (
            <GroupPairing
              data={data}
              sortColumn={sortColumn}
              healthFilter="poor"
              searchKeyword={searchKeyword}
              sortType={sortType}
              program={program}
              loading={loading}
              handleSortColumn={handleSortColumn}
              handleDrawer={handleDrawer}
            />
          )}
        </div>
      </div>

      {/* Section for Drawer */}
      <RatingDetailsDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        groupId={groupId}
        mentee={mentee}
        mentor={mentor}
        type={drawerType}
      />
    </>
  );
};

export default MentorMenteeHealth;
