import React from "react";
import { Panel } from "rsuite";
import UserSatisfactionDonutChart from "../charts/UserSatisfactionDonutChart";
import { useNpsSummary } from "../hooks/usePortalAnalytics";
import { usePortal } from "contexts/portal";
import GenericMsgLoader from "components/shared/GenericMsgLoader";

const UserSatisfactionScorePanel = ({ userType }) => {
  const { program } = usePortal();
  const { data, isLoading, isError, error } = useNpsSummary(
    program.id,
    userType
  );

  const {
    total_respondents: totalRespondentsCount,
    satisfaction_score: satisfactionScore,
  } = data || {};

  if (isError) {
    console.error("Error fetching latest activities:", error);
    return <div>Error loading activities</div>;
  }

  return (
    <>
      {/* User Satisfaction Score Chart */}
      <div className="tw-col-span-2">
        <div className="tw-mb-2">
          <h6>User Satisfaction Score</h6>
        </div>

        <Panel bordered className="tw-py-4 tw-flex tw-justify-center">
          <div className="tw-text-center">
            <h6 className="tw-text-3xl tw-font-bold tw-m-0">
              {satisfactionScore}
            </h6>
            <span className="tw-text-sm">Satisfaction Score</span>
          </div>
          <div className="tw-relative tw-my-4">
            {isLoading ? (
              <GenericMsgLoader />
            ) : (
              <>
                <UserSatisfactionDonutChart data={data} isLoading={isLoading} />
                <div
                  className="tw-flex tw-justify-center tw-flex-col tw-text-center"
                  style={{
                    position: "absolute",
                    top: "42%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <span className="tw-text-2xl tw-font-bold">
                    {totalRespondentsCount}
                  </span>
                  <span className="tw-text-sm">respondents</span>
                </div>
              </>
            )}
          </div>
        </Panel>
      </div>
    </>
  );
};

export default UserSatisfactionScorePanel;
