import React, { useState } from "react";
import CalendarSelector from "../components/CalendarSelector";

import moment from "moment";
import UserEngagementActivityPanel from "../components/UserEngagementActivityPanel";

const UserEngagement = () => {
  const [date, setDate] = useState(moment());
  const [totalActivity, setTotalActivity] = useState(0);

  return (
    <>
      {/* Title and Export Button Action */}
      <div className="tw-my-10">
        <h6 className="tw-text-xl">User Engagement</h6>
        <p className="tw-text-sm">Monthly user activity and interactions.</p>
      </div>

      {/* Pairing, Learning Experience, Sessions, Users Panel Section */}
      <div className="tw-my-10">
        <h6>Monthly progress</h6>
        <div className="tw-flex">
          <div className="tw-mr-4">
            <CalendarSelector
              date={date}
              setDate={setDate}
              setTotalActivity={setTotalActivity}
            />
          </div>
          <div className="tw-w-full tw-px-4">
            <UserEngagementActivityPanel
              date={date}
              totalActivity={totalActivity}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEngagement;
