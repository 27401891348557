// FUT-1617: New API v2 created for Portal Analytics

import { createRailsApiInstance } from "api/config";

// TODO: Build API route for Analytics
// Change this to match your environment
const ADMIN_BASE_URL = {
  development: 'http://lvh.me:3000/api/v2/portal/admin/',
  staging: "https://futurelab.global/api/v2/portal/admin/",
  production: "https://futurelab.my/api/v2/portal/admin/"
}

const environment = process.env.RAILS_ENV || "development";
const api = createRailsApiInstance(ADMIN_BASE_URL[environment]);

// @Portal Analytics - Program Overview
// Session Completion
export const getSessionCompletion = async (programId: number, fromDate: Date = null, toDate: Date = null) => {
  // need to convert to String. Date payload auto converts to UTC, which backdates it by a day.

  return await api.get(`/session_completion`, {
    params: {
      program_id: programId,
      from_date: fromDate ? fromDate.toString() : null,
      to_date: toDate ? toDate.toString() : null
    },
  });
}

// Learning EXP Summary
export const getLearningExpSummary = async (programId: number) => {
  return await api.get(`/learning_experience_summary`, {
    params: { program_id: programId },
  });
}

// Latest Activities
export const getLatestActivities = async (programId: number) => {
  return await api.get(`/latest_activities`, {
    params: { program_id: programId },
  });
}

// Booking Summary
export const getBookingSummary = async (programId: number) => {
  return await api.get(`/booking_summary`, {
    params: { program_id: programId },
  });
}

// Pairing Summary
export const getPairingSummary = async (programId: number) => {
  return await api.get(`/pairing_summary`, {
    params: { program_id: programId },
  });
}

// User Summary
export const getUserSummary = async (programId: number) => {
  return await api.get(`/user_summary`, {
    params: { program_id: programId },
  });
}

// Survey Summary
export const getSurveySummary = async (programId: number) => {
  return await api.get(`/survey_summary`, {
    params: { program_id: programId },
  });
}

// @Portal Analytics - Learning Progress
export const getLearningProgress = async (programId: number) => {
  return await api.get(`/learning_progress`, {
    params: { program_id: programId },
  });
}

// @Portal Analytics - User Engagement
// Activities
export const getActivities = async (programId: number, date: Date = null, page: number = 1) => {
  return await api.get(`/activities`, {
    params: { program_id: programId, date: date, page: page },
  });
}

// Monthly Activities
export const getMonthlyActivities = async (programId: number, month: number, year: number) => {
  return await api.get(`/monthly_activities_overview`, {
    params: { program_id: programId, month: month, year: year },
  });
}

// @Portal Analytics - Mentor Mentee Health
// Pairing Health Summary
export const getPairingHealthSummary = async (programId: number, type: string = "pair") => {
  return await api.get(`/pairing_health_summary`, {
    params: { program_id: programId, type: type },
  });
}

// @Portal Analytics - User Insights
// NPS Summary
export const getNpsSummary = async (programId: number, userType: string = 'mentee') => {
  return await api.get(`/nps_summary`, {
    params: { program_id: programId, type: userType },
  });
}

// Reflections Summary
export const getReflectionsSummary = async (programId: number, userType: string = "mentee") => {
  return await api.get(`/latest_reflections`, {
    params: { program_id: programId, type: userType },
  });
}

// Goals Summary
export const getGoalsSummary = async (programId: number, userType: string = "mentee") => {
  return await api.get(`/goal_summary`, {
    params: { program_id: programId, type: userType },
  });
}

export const getChallengesSummary = async (programId: number, userType: string = "mentee") => {
  return await api.get(`/challenges_summary`, {
    params: { program_id: programId, type: userType },
  });
}

export const getSkillsSummary = async (programId: number, userType: string = "mentee") => {
  return await api.get(`/skills_summary`, {
    params: { program_id: programId, type: userType },
  });
}

export const getIndustriesSummary = async (programId: number, userType: string = "mentee") => {
  return await api.get(`/industries_summary`, {
    params: { program_id: programId, type: userType },
  });
}