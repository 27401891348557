// React
import React, { useCallback } from "react";

// FutureLab
import { FutureLabApi } from "../../util/api";
import { useUser } from "../../contexts/user";
import { JobInput } from "./types";
import {
  FormDatePicker,
  FormInputText,
} from "components/form/default/FormInputs";
import Toaster from "components/shared/Toaster";
import FieldError from "components/form/FieldError";
import { jobSchema } from "./schema";

// UI
import { Button, Checkbox, Form, InputPicker } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import _, { debounce } from "lodash";

const JobForm = ({
  job,
  companies,
  handleSearch,
  closeJobModal,
  loading,
  initializedDeleteJobConfimartionModal,
}) => {
  const { user, refreshUser } = useUser();

  const debouncedFetchCompanies = useCallback(
    debounce((searchKeyword) => {
      if (searchKeyword) {
        handleSearch(searchKeyword);
      }
    }, 300), // Adjust debounce delay as needed
    []
  );

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<JobInput>({
    resolver: yupResolver(jobSchema),
    defaultValues: {
      user_id: user.id,
      name: job?.name || "",
      company_name: job?.company?.name || "",
      description: job?.description || "",
      start_date: job?.start_date && new Date(job?.start_date),
      end_date: job?.end_date && new Date(job?.end_date),
      is_current: job?.is_current || false,
    },
  });

  const watchIsCurrent = watch("is_current");
  const watchStartDate = watch("start_date");

  const onSubmit = (data) => {
    FutureLabApi({
      method: job ? "patch" : "post",
      path: job ? `/jobs/${job.id}` : `/jobs`,
      options: data,
      success: ({ data }) => {
        refreshUser();
        closeJobModal();
        Toaster({
          type: "success",
          msg: "Job successfully saved",
        });
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <Form fluid className="tw-pt-4">
      <Form.Group>
        <FormInputText
          control={control}
          errors={errors}
          name="name"
          label="Job Title"
          placeholder="Title"
          size="lg"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          Company
        </Form.ControlLabel>
        <Controller
          name="company_name"
          control={control}
          render={({ field }) => (
            <InputPicker
              {...field}
              block
              creatable
              data={companies}
              placeholder="Search"
              valueKey="name"
              onSearch={debouncedFetchCompanies}
              searchBy={() => true}
              renderMenu={(menu) => {
                if (loading) {
                  return (
                    <p
                      style={{
                        color: "#999",
                        textAlign: "center",
                      }}
                    >
                      <SpinnerIcon spin /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
            />
          )}
        />
        <ErrorMessage errors={errors} name="company_name" as={FieldError} />
      </Form.Group>
      <Form.Group>
        <FormInputText
          control={control}
          errors={errors}
          name="description"
          label="Description"
          as="textarea"
          rows={4}
          size="lg"
        />
      </Form.Group>
      <div className="tw-flex tw-gap-2">
        <Form.Group className="tw-flex-1 tw-mb-0">
          <FormDatePicker
            control={control}
            errors={errors}
            name="start_date"
            label="Start date"
            placeholder="Select date"
            editable={true}
            oneTap
            format="MMM yyyy"
            size="lg"
          />
        </Form.Group>
        <Form.Group className="tw-flex-1 tw-mb-0">
          <FormDatePicker
            shouldDisableDate={(date) =>
              DateTime.fromJSDate(date) < DateTime.fromJSDate(watchStartDate)
            }
            control={control}
            errors={errors}
            name="end_date"
            label="End date"
            placeholder="Select date"
            oneTap
            editable={true}
            disabled={watchIsCurrent}
            format="MMM yyyy"
            size="lg"
          />
        </Form.Group>
      </div>
      <Form.Group>
        <Controller
          name="is_current"
          control={control}
          render={({ field }) => (
            <Checkbox
              name="is_current"
              defaultChecked={field.value}
              onChange={(value, checked) => {
                setValue(field.name, checked);
              }}
            >
              <span style={{ fontSize: 14 }}>I'm currently working here</span>
            </Checkbox>
          )}
        />
        <ErrorMessage errors={errors} name="is_current" as={FieldError} />
      </Form.Group>
      <div className="tw-text-right">
        {job && (
          <Button
            appearance="link"
            className="tw-text-[#d12f1d] tw-mr-2"
            onClick={initializedDeleteJobConfimartionModal}
          >
            Remove
          </Button>
        )}
        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default JobForm;
