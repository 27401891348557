import { useApiQuery } from "api/useApiQuery";
import {
  getSessionCompletion,
  getLearningExpSummary,
  getLatestActivities,
  getBookingSummary,
  getPairingSummary,
  getUserSummary,
  getLearningProgress,
  getActivities,
  getMonthlyActivities,
  getSurveySummary,
  getNpsSummary,
  getGoalsSummary,
  getReflectionsSummary,
  getSkillsSummary,
  getChallengesSummary,
  getIndustriesSummary,
  getPairingHealthSummary,
} from "api/portal/analytics/analyticsApi";
import { useInfiniteQuery } from "@tanstack/react-query";

// @Program Overview --
// Hook for session completion
export const useSessionCompletion = (
  programId: number,
  dateRange?: { from_date: Date; to_date: Date }
) => {
  return useApiQuery(
    ["session_completion", programId, dateRange],
    async () => {
      const { data } = await getSessionCompletion(
        programId,
        dateRange?.from_date,
        dateRange?.to_date
      );
      return data;
    },
    { staleTime: 60000 }
  );
};

// Hook for learning experience summary
export const useLearningExpSummary = (programId: number) => {
  return useApiQuery(
    ["learning_exp_summary", programId],
    async () => {
      const { data } = await getLearningExpSummary(programId);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for latest activities
export const useLatestActivities = (programId: number) => {
  return useApiQuery(
    ["latest_activities", programId],
    async () => {
      const { data } = await getLatestActivities(programId);
      return {
        current_page: data.current_page,
        data: data.data,
        lastpage: data.last_page,
      };
    },
    { staleTime: 30000 }
  );
};

// Hook for booking summary
export const useBookingSummary = (programId: number) => {
  return useApiQuery(
    ["booking_summary", programId],
    async () => {
      const { data } = await getBookingSummary(programId);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for pairing summary
export const usePairingSummary = (programId: number) => {
  return useApiQuery(
    ["pairing_summary", programId],
    async () => {
      const { data } = await getPairingSummary(programId);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for user summary
export const useUserSummary = (programId: number) => {
  return useApiQuery(
    ["user_summary", programId],
    async () => {
      const { data } = await getUserSummary(programId);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

export const useSurveySummary = (programId: number) => {
  return useApiQuery(
    ["survey_summary", programId],
    async () => {
      const { data } = await getSurveySummary(programId);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// @User Engagement
// Hook for activities - Infinite Scrolling for large activities.
export const useActivities = (programId: number, date) => {
  return useInfiniteQuery({
    queryKey: ["activities", programId, date],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getActivities(programId, date, pageParam);
      return {
        data: data.data,
        nextPage: pageParam + 1,
        hasMore: data.data.length > 0,
      };
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    staleTime: 30000,
    initialPageParam: 1,
  });
};

// Hook for monthly activities
export const useMonthlyActivities = (
  programId: number,
  month: number,
  year: number
) => {
  return useApiQuery(
    ["monthly_activities", programId, month, year],
    async () => {
      const { data } = await getMonthlyActivities(programId, month, year);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// @Mentor-Mentee Health
// Hook for pairing health summary
export const usePairingHealthSummary = (
  programId: number,
  type: string = "pair"
) => {
  return useApiQuery(
    ["pairing_health_summary", programId, type],
    async () => {
      const { data } = await getPairingHealthSummary(programId, type);
      return data.data;
    },
    { staleTime: 120000 }
  );
};

// @Learning Progress
// Hook for learning progress
export const useLearningProgress = (programId: number) => {
  return useApiQuery(
    ["learning_progress", programId],
    async () => {
      const { data } = await getLearningProgress(programId);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// @User Insights
// Hook for nps summary
export const useNpsSummary = (
  programId: number,
  userType: string = "mentee"
) => {
  return useApiQuery(
    ["nps_summary", programId, userType],
    async () => {
      const { data } = await getNpsSummary(programId, userType);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for reflections summary
export const useLatestReflectionsSummary = (
  programId: number,
  userType: string = "mentee"
) => {
  return useApiQuery(
    ["reflections_summary", programId, userType],
    async () => {
      const { data } = await getReflectionsSummary(programId, userType);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for goals summary
export const useGoalsSummary = (
  programId: number,
  userType: string = "mentee"
) => {
  return useApiQuery(
    ["goals_summary", programId, userType],
    async () => {
      const { data } = await getGoalsSummary(programId, userType);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for skills summary
export const useSkillsSummary = (
  programId: number,
  userType: string = "mentee"
) => {
  return useApiQuery(
    ["skills_summary", programId, userType],
    async () => {
      const { data } = await getSkillsSummary(programId, userType);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

// Hook for challenges summary
export const useChallengesSummary = (
  programId: number,
  userType: string = "mentee"
) => {
  return useApiQuery(
    ["challenges_summary", programId, userType],
    async () => {
      const { data } = await getChallengesSummary(programId, userType);
      return data.data;
    },
    { staleTime: 30000 }
  );
};

export const useIndustriesSummary = (
  programId: number,
  userType: string = "mentee"
) => {
  return useApiQuery(
    ["industries_summary", programId, userType],
    async () => {
      const { data } = await getIndustriesSummary(programId, userType);
      return data.data;
    },
    { staleTime: 30000 }
  );
};
