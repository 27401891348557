import React from "react";
import { Panel } from "rsuite";
import GenericMsgLoader from "components/shared/GenericMsgLoader";

const InsightsPanel = ({ title, data, isLoading, dataKeys }) => {
  const items = data || [];

  return (
    <>
      <div className="tw-mb-2">
        <span className="tw-text-base tw-font-bold">{title}</span>
        <span className="tw-text-sm"> - Top 5</span>
      </div>
      <Panel bordered className="tw-min-h-[324px] sm:tw-min-h-1">
        {isLoading ? (
          <div className="tw-w-full tw-flex tw-items-center">
            <GenericMsgLoader />
          </div>
        ) : items.length > 0 ? (
          items.map((item, idx) => (
            <div
              key={idx}
              className="tw-grid tw-grid-cols-4 tw-py-4"
              style={{
                borderBottom:
                  idx !== items.length - 1 ? "1px solid #eEE5EA" : "none",
              }}
            >
              <div className="tw-col-span-2">
                <span className="tw-text-sm">{item[dataKeys?.name]}</span>
              </div>
              <div className="tw-col-span-1 tw-text-center">
                <span className="tw-text-sm">{item[dataKeys?.totalUsers]}</span>
              </div>
              <div className="tw-col-span-1 tw-flex tw-justify-end">
                <span className="tw-text-sm">
                  ({item[dataKeys?.percentage]}%)
                </span>
              </div>
            </div>
          ))
        ) : (
          <div>No data available.</div>
        )}
      </Panel>
    </>
  );
};

export default InsightsPanel;
