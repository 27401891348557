import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const SurveyDonutChart = ({ completed, ongoing }) => {
  const series = [completed || 0, ongoing || 0];
  const options: ApexOptions = {
    labels: ["Completed", "Ongoing"],
    legend: {
      show: true,
      position: "bottom",
      markers: {
        radius: 6,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={350}
    />
  );
};

export default SurveyDonutChart;
