import React, { useEffect, useState } from "react";
import { Col, Divider, Grid, Loader, Row, toaster } from "rsuite";
import { FlexboxGrid } from "rsuite";
import {
  MdAccessTime,
  MdOutlineEmail,
  MdOutlineLocationOn,
  MdOutlinePhone,
  MdShare,
} from "react-icons/md";
import { FutureLabApi } from "../../util/api";
import EventFormModal from "./EventFormModal";
import EventTicketForm from "./forms/EventTicketForm";
import MobileTicketDrawer from "./components/MobileTicketDrawer";
import { Message } from "rsuite";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EventSpeakers from "./components/EventSpeakers";
import EventMyTicketModal from "./EventMyTicketModal";
import { useUser } from "../../contexts/user";
import { RichTextEditorToHTML } from "../ui/RichTextEditor";

const EventShow = ({ eventId }) => {
  const [speakers, setSpeakers] = useState<any>([]);
  const [tickets, setTickets] = useState<any>([]);
  const [userTickets, setUserTickets] = useState<any>([]);
  const [purchasedTickets, setPurchasedTickets] = useState<any>([]);
  const [event, setEvent] = useState<any>();
  const [openModal, setOpenModal] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    if (eventId) {
      FutureLabApi({
        method: "get",
        path: `/events/${eventId}`,
        options: { user_id: user ? user.id : null },
        success: ({ data }) => {
          setEvent(data.event);
          setTickets(data.event.tickets);
          setSpeakers(data.speakers);
        },
        error: () => {},
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (user) {
      FutureLabApi({
        method: "get",
        path: `/tickets/${user.id}`,
        options: { event_id: eventId },
        success: ({ data }) => {
          setUserTickets(data);
        },
        error: () => {},
      });
    }
  }, [user]);

  const linkValidation = (link) => {
    if (link.startsWith("www")) {
      return "https://" + link;
    }
    return link;
  };

  function handleNewLines(data) {
    // Replace '\n' with '<br>' to preserve line breaks in HTML
    return data.replace(/\n/g, "<br>");
  }

  const handleOpenModal = () => setOpenModal(!openModal);

  if (!event) return <Loader center content="Loading" />;

  return (
    <FlexboxGrid className="tw-my-3" justify="center">
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} lg={user ? 22 : 14}>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={24} md={16}>
              <div>
                <img
                  src={event.photo_hq_url}
                  alt="photo_thumbnail_url"
                  className="tw-w-full tw-rounded-xl "
                />
                <div className="tw-w-full tw-mt-4 tw-mb-10">
                  <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
                    <div>
                      <p className="montserrat tw-font-semibold tw-m-0">
                        {event.date}
                      </p>
                    </div>
                    <div>
                      <CopyToClipboard
                        text={window.location.href}
                        onCopy={() =>
                          toaster.push(
                            <Message showIcon type="success">
                              Copied to clipboard
                            </Message>
                          )
                        }
                      >
                        <a className="tw-flex tw-items-center tw-gap-1">
                          <MdShare style={{ color: " #428ac9" }} />
                          <span style={{ color: " #428ac9" }}>Share</span>
                        </a>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <h1 className="tw-text-xl sm:tw-text-base">{event.title}</h1>
                </div>
              </div>

              <div className="tw-my-4">
                <h2 className="tw-text-base">Where and when</h2>
                <Divider className="tw-mt-0" />
                <div className="tw-flex sm:tw-flex-col">
                  <div className="tw-w-1/2 sm:tw-w-full">
                    <div className="tw-flex">
                      <div className="tw-inline tw-mr-2 ">
                        <MdOutlineLocationOn className="tw-text-lg" />
                      </div>
                      <div className="tw-inline">
                        <p className="tw-font-medium tw-mb-1 montserrat">
                          Location
                        </p>
                        <p className="sm:tw-text-sm montserrat tw-m-0">
                          {event.event_type}
                        </p>
                        {event.venue_title && (
                          <p className="sm:tw-text-sm montserrat tw-m-0"> </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tw-w-1/2 sm:tw-w-full sm:tw-my-6">
                    <div className="tw-flex">
                      <div className="tw-inline tw-mr-2">
                        <MdAccessTime />
                      </div>
                      <div className="tw-m-0 tw-inline">
                        <p className="tw-font-medium tw-mb-1 montserrat">
                          Date and Time
                        </p>
                        <div>
                          <p className="sm:tw-text-sm tw-m-0 montserrat">
                            {event.date}
                          </p>
                          <p className="sm:tw-text-sm tw-mb-1 montserrat">
                            {event.start_time} - {event.end_time}
                          </p>
                          <p className="montserrat tw-text-sm">
                            • Your local timezone
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {speakers.length > 0 && <EventSpeakers speakers={speakers} />}

              <div className="tw-my-6 tw-py-2">
                <h2 className="tw-text-base">About</h2>
                <Divider className="tw-mt-0" />
                <RichTextEditorToHTML
                  content={handleNewLines(event.description)}
                />
                {/* <p className="montserrat">{event.description}</p> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className="sm:tw-hidden">
                {tickets && !event.event_ended && (
                  <>
                    <h2 className="tw-text-base">Admission</h2>
                    <Divider className="tw-mt-0" />

                    <EventTicketForm
                      tickets={tickets}
                      handleOpenModal={handleOpenModal}
                      setPurchasedTickets={setPurchasedTickets}
                      event={event}
                    />
                    {event.total_attendees > 0 && (
                      <p className="montserrat tw-text-sm tw-text-center tw-my-2">
                        {user && userTickets.length > 0 && "You and "}
                        <b>{event.total_attendees}</b> people are attending.
                      </p>
                    )}
                  </>
                )}
              </div>
              {user && userTickets.length > 0 && (
                <div className="tw-my-6 tw-py-2">
                  <h2 className="tw-text-base">Your tickets</h2>
                  <Divider className="tw-mt-0" />
                  <EventMyTicketModal userTickets={userTickets} />
                  {event.webinar_link && (
                    <div className="tw-my-2 tw-py-2">
                      <a
                        href={linkValidation(event.webinar_link)}
                        target="_blank"
                      >
                        Webinar link
                      </a>
                    </div>
                  )}
                </div>
              )}

              <div className="tw-my-6 tw-py-2 sm:tw-my-0 sm:tw-py-0">
                <h2 className="tw-text-base">Organiser</h2>
                <Divider className="tw-mt-0" />
                <p className="montserrat tw-mb-1">{event.organizer_name}</p>
                <p className="montserrat tw-m-0">
                  <MdOutlineEmail className="tw-mr-2" />
                  {event.inquiry_email}
                </p>
                <p className="montserrat tw-m-0">
                  <MdOutlinePhone className="tw-mr-2" />
                  {event.inquiry_number}
                </p>
              </div>
              <EventFormModal
                open={openModal}
                eventId={eventId}
                handleOpenModal={handleOpenModal}
                purchasedTickets={purchasedTickets}
              />
            </Col>
          </Row>
        </Grid>
      </FlexboxGrid.Item>
      <MobileTicketDrawer
        tickets={tickets}
        event={event}
        handleOpenModal={handleOpenModal}
        setPurchasedTickets={setPurchasedTickets}
      />
    </FlexboxGrid>
  );
};

export default EventShow;
