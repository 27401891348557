// React
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import { FutureLabApi } from "../../util/api";
import Toaster from "components/shared/Toaster";
import FieldError from "components/form/FieldError";
import { UserAvailability } from "./types";
import { availabilitySchema } from "./schema";

// UI
import { Button, Divider, Form, InputPicker, Toggle } from "rsuite";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

const Availability = () => {
  const { variables, user, setUser, refreshUser } = useOutletContext() as any;
  const { enterpriseSlug, programSlug, onPortal, programId } = variables;
  const [timezones, setTimezones] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UserAvailability>({
    resolver: yupResolver(availabilitySchema),
    defaultValues: {
      user: {
        timezone: user.timezone,
      },
      availability: {
        program_id: programId || null,
        dnd: user.dnd,
      },
    },
  });

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/timezones",
      success: ({ data }) => {
        setTimezones(data);
      },
    });
  }, []);

  const onSubmit = (data) => {
    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        refreshUser();
        // setUser(data); # Need to modify endpoint to include program_id variable
        Toaster({
          type: "success",
          msg: "Availability successfully updated",
        });
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <Form fluid>
      {user.user_type === "mentor" && (
        <>
          <Form.Group className="tw-flex tw-justify-between tw-items-center">
            <Form.ControlLabel className="tw-text-sm tw-font-medium tw-mb-0">
              Set my upcoming availability
            </Form.ControlLabel>
            <Button
              appearance="primary"
              href={
                onPortal
                  ? `/app/${enterpriseSlug}/${programSlug}/availability`
                  : "/main/availability"
              }
            >
              Set availability
            </Button>
          </Form.Group>
          <Divider />
        </>
      )}
      <Form.Group className="tw-flex tw-justify-between tw-items-center xs:tw-block">
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          Timezone
        </Form.ControlLabel>
        <Controller
          name="user.timezone"
          control={control}
          render={({ field }) => (
            <InputPicker
              data={timezones}
              style={{ width: 400 }}
              placeholder="Please select"
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage errors={errors} name="user.timezone" as={FieldError} />
      </Form.Group>
      {user.user_type === "mentor" && (
        <Form.Group className="tw-flex tw-justify-between">
          <Form.ControlLabel className="tw-text-sm tw-font-medium">
            Do not Disturb
            <Form.HelpText tooltip>
              Turn on "Do Not Disturb" to go offline and unavailable for
              bookings
            </Form.HelpText>
          </Form.ControlLabel>
          <Controller
            name="availability.dnd"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Toggle
                checked={value}
                onChange={onChange}
                checkedChildren="Enable"
                unCheckedChildren="Disable"
                size="lg"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="availability.dnd"
            as={FieldError}
          />
        </Form.Group>
      )}
      <div className="tw-text-right">
        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default Availability;
