export const filterData = (data, searchKeyword = "", healthFilter = "") => {
  let filteredData = data;
  let keyWordSearch = searchKeyword?.toLowerCase();

  if (searchKeyword.length > 0) {
    filteredData = data.filter(({ mentee_participants, mentor_participants, name, id }) => {
      let isMatched = false;

      const checkParticipantFullName = (participants) => {
        if (Array.isArray(participants)) {
          return participants.some(participant =>
            participant?.full_name && participant?.full_name.toLowerCase().includes(keyWordSearch)
          );
        } else {
          return participants?.full_name && participants?.full_name.toLowerCase().includes(keyWordSearch);
        }
      };

      isMatched = checkParticipantFullName(mentee_participants) || checkParticipantFullName(mentor_participants);

      return isMatched ||
        (name ? name.toLowerCase().includes(keyWordSearch) : false) ||
        (id ? `${id}`.includes(keyWordSearch) : false);
    });
  }

  if (healthFilter.length > 0) {
    filteredData = filteredData.filter(({ pairing_health_score }) => {
      if (healthFilter.includes("great") && pairing_health_score === "1") {
        return true;
      } else if (
        healthFilter.includes("fair") &&
        pairing_health_score === "2"
      ) {
        return true;
      } else if (
        healthFilter.includes("poor") &&
        pairing_health_score === "3"
      ) {
        return true;
      }
      return false;
    });
  }

  return filteredData;
};
