import React from "react";
import { usePortal } from "contexts/portal";
import LearningProgressCard from "../components/LearningProgressCard";
import { useLearningProgress } from "../hooks/usePortalAnalytics";
import GenericMsgLoader from "components/shared/GenericMsgLoader";

const LearningProgress = () => {
  const { program } = usePortal();
  const { data, isLoading } = useLearningProgress(program.id);

  const modules = data || [];

  return (
    <>
      {/* Title and Export Button Action */}
      <div className="tw-my-10">
        <h6 className="tw-text-xl">Learning Progress</h6>
        <p className="tw-text-sm">
          Status of user advancement through your program agendas.
        </p>
      </div>

      {isLoading ? (
        <GenericMsgLoader />
      ) : modules.length === 0 ? (
        <div>
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-sm tw-text-gray-600">
              No learning progress data found.
            </p>
          </div>
        </div>
      ) : (
        <>
          {/* Module Progress Section */}
          {modules?.map((module, index) => (
            <LearningProgressCard module={module} index={index} />
          ))}
        </>
      )}
    </>
  );
};

export default LearningProgress;
