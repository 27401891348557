// React
import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import FieldError from "../ui/FieldError";
import Api, { FutureLabApi } from "../../util/api";
import {
  FormInputPicker,
  FormTagPicker,
} from "components/form/default/FormInputs";
import Toaster from "components/shared/Toaster";
import { UserMatching } from "./types";
import { matchingSchema } from "./schema";

// UI
import { Button, Form, TagPicker } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import _, { debounce, map } from "lodash";

// 25/6/23: FUT-1137: Limit the industry selection list
// List is based on production DB IDs
const approvedIndustryList = [
  585, 1186, 231, 3772, 1999, 167, 58, 223, 685, 2079,
];

const fetchCompanies = async (query = "") => {
  return await Api.get("/companies", { search: query });
};

const Matching = () => {
  const { user, setUser, refreshUser, variables } = useOutletContext() as any;
  const [industries, setIndustries] = useState([]);
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserMatching>({
    defaultValues: {
      industries_attributes: map(
        user.interested_industries,
        (value) => value.id
      ),
      goals_attributes: map(user.goals, (value) => value.id),
      program_goals: map(user.program_goals, (value) => value.id),
      program_id: variables?.programId || null,
      companies_attributes: map(user.interested_companies, (value) => value.id),
      info: {
        working_experience: user.info.working_experience,
      },
    },
    resolver: yupResolver(matchingSchema),
  });

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const { data } = await fetchCompanies(query);
      setCompanies(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchCompanies = useCallback(
    debounce((searchKeyword) => {
      if (searchKeyword) {
        handleSearch(searchKeyword);
      }
    }, 300), // Adjust debounce delay as needed
    []
  );

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/industries",
      success: ({ data }) => {
        let filteredIndustries = data
          .filter((obj) => approvedIndustryList.includes(obj.id))
          .sort((a, b) => a.name.localeCompare(b.name));
        setIndustries(filteredIndustries);
      },
    });

    FutureLabApi({
      method: "get",
      path: "/goals",
      options: { program_id: variables?.programId },
      success: ({ data }) => {
        setGoals(data);
      },
    });

    const preLoadCompaniesData = async () => {
      const { data } = await fetchCompanies();
      const mergedCompanies = [...user?.interested_companies, ...data];

      const uniqueCompanies = Array.from(
        new Set(mergedCompanies.map((company) => company.id))
      ).map((id) => mergedCompanies.find((company) => company.id === id));
      setCompanies(uniqueCompanies);
    };
    preLoadCompaniesData();
  }, []);

  const onSubmit = (data) => {
    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        refreshUser();
        // setUser(data); # Need to modify endpoint to include program_id variable
        Toaster({
          type: "success",
          msg: "Matching preferences successfully updated",
        });
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <Form fluid className="tw-mb-6">
      <Form.Group>
        <FormTagPicker
          control={control}
          errors={errors}
          name={variables.onPortal ? "program_goals" : "goals_attributes"}
          label="What are your career goals?"
          data={goals}
          size="lg"
          block
          placeholder="Select goals you want to achieved"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          What job roles are you most interested in?
        </Form.ControlLabel>
        <Controller
          name="industries_attributes"
          control={control}
          render={({ field }) => (
            <TagPicker
              // disabledItemValues={
              //   field.value?.length === 3
              //     ? [...industries]
              //         .map((obj) => obj.id)
              //         .filter((id) => !field.value.includes(id))
              //     : []
              // }
              data={industries}
              searchable={field.value?.length < 3}
              block
              placeholder="Please select"
              size="lg"
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="industries_attributes"
          as={FieldError}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          What company would you love your mentor to be from?
        </Form.ControlLabel>
        <Controller
          name="companies_attributes"
          control={control}
          render={({ field }) => (
            <TagPicker
              {...field}
              disabledItemValues={
                field.value?.length === 3
                  ? [...companies]
                      .map((obj) => obj.id)
                      .filter((id) => !field.value.includes(id))
                  : []
              }
              block
              creatable
              size="lg"
              data={companies}
              valueKey="id"
              searchable={field.value?.length < 3}
              placeholder="Search companies"
              onSearch={debouncedFetchCompanies}
              searchBy={() => true}
              renderMenu={(menu) => {
                if (loading) {
                  return (
                    <p
                      style={{
                        color: "#999",
                        textAlign: "center",
                      }}
                    >
                      <SpinnerIcon spin /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="companies_attributes"
          as={FieldError}
        />
      </Form.Group>
      <Form.Group>
        <FormInputPicker
          data={_.map(user.info.working_experience_list, (value, key) => {
            return { label: value, value: value };
          })}
          control={control}
          errors={errors}
          placeholder="Please select"
          name="info.working_experience"
          label="How experienced should your mentor be?"
          size="lg"
          block
        />
      </Form.Group>
      <div className="tw-text-right">
        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default Matching;
