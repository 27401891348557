import React, { useEffect, useState } from "react";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";
import { usePortal } from "contexts/portal";
import { useMonthlyActivities } from "../hooks/usePortalAnalytics";

// Need to initialize react-dates
import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";
import "../css/CalendarSelector.css";
import { Button, Loader } from "rsuite";

const CalendarSelector = ({ date, setDate, setTotalActivity }) => {
  const [visibleMonth, setVisibleMonth] = useState(moment());
  const [showCalendar, setShowCalendar] = useState(true);
  const [focused, setFocused] = useState(true);
  const { program } = usePortal();

  // Required to add + 1 to month as it's zero indexed
  const month = visibleMonth.month() + 1;
  const year = visibleMonth.year();

  const {
    data = {},
    isLoading,
    isError,
    error,
  } = useMonthlyActivities(program?.id, month, year);

  useEffect(() => {
    if (data) {
      const today = moment().format("YYYY-MM-DD");
      setTotalActivity(data[today]);
    }
  }, [data]);

  // Custom content rendering
  const renderDayContents = (day) => {
    const dateKey = day.format("YYYY-MM-DD");
    const activityCount = data[dateKey] || 0;

    if (isLoading) {
      return (
        <div style={{ textAlign: "center" }}>
          <div>{day.format("D")}</div>
          <div style={{ fontSize: "0.2em" }}>
            <Loader />
          </div>
        </div>
      );
    }

    return (
      <div style={{ textAlign: "center" }}>
        <div>{day.format("D")}</div>
        <div
          style={{
            fontSize: "0.8em",
          }}
        >
          {activityCount}
        </div>
      </div>
    );
  };

  const handleSelectDate = (date) => {
    const formatDate = date.format("YYYY-MM-DD");

    setDate(date);
    setTotalActivity(data[formatDate]);
  };

  const isDayHighlighted = (day) => {
    const dateKey = day.format("YYYY-MM-DD");
    return data?.[dateKey] > 0;
  };

  const handleResetToToday = () => {
    // date-picker doesn't have a functionality to reset to 'today'
    // initialVisibleMonth only renders once on mount
    // only solution is the unmount and remount the calendar

    setVisibleMonth(moment());
    setShowCalendar(false);
    setTimeout(() => setShowCalendar(true), 0);
  };

  if (isError) {
    return <div>Failed to load data</div>;
  }

  return (
    <>
      {showCalendar && (
        <DayPickerSingleDateController
          initialVisibleMonth={() => visibleMonth}
          date={date}
          daySize={57}
          onNextMonthClick={() =>
            setVisibleMonth(visibleMonth.clone().add(1, "month"))
          }
          onPrevMonthClick={() =>
            setVisibleMonth(visibleMonth.clone().subtract(1, "month"))
          }
          transitionDuration={0}
          verticalBorderSpacing={5}
          horizontalMonthPadding={5}
          isDayHighlighted={isDayHighlighted}
          onDateChange={(newDate) => handleSelectDate(newDate)}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          renderDayContents={renderDayContents}
          numberOfMonths={1}
          noBorder
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
        />
      )}
      <div
        className="tw-w-full tw-flex tw-justify-end tw-p-4 tw-mt-4"
        style={{ borderTop: "1px solid #e8e8e8" }}
      >
        <Button appearance="link" onClick={handleResetToToday}>
          Reset Month
        </Button>
      </div>
    </>
  );
};

export default CalendarSelector;
