import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

// Define environment-based URLs for different contexts
const urls = {
  default: {
    development: "http://lvh.me:3000/api/v1",
    production: "https://api.futurelab.my/api/v1",
    staging: "https://futurelab.global/api/v1",
  },
  portal: {
    development: "http://lvh.me:3000/api/portal/v1",
    production: "https://api.futurelab.my/api/portal/v1",
    staging: "https://futurelab.global/api/portal/v1",
  },
  enterprise: {
    development: "http://lvh.me:3000/api/enterprise/v1",
    production: "https://api.futurelab.my/api/enterprise/v1",
    staging: "https://futurelab.global/api/enterprise/v1",
  },
  rails: {
    development: "/",
    production: "/",
    staging: "/",
  },
};

/**
 * Get the root URL based on the context and environment
 * @param context - The API context (default, portal, enterprise, etc.)
 * @returns The base URL for the given context and environment
 */
const getRootUrl = (context: keyof typeof urls = "default"): string => {
  const environment = process.env.RAILS_ENV || "development";
  return urls[context][environment];
};

/**
 * Retrieve the token from cookies
 * @returns The token as a string
 */
const getToken = (): string | undefined =>
  Cookies.get("pwa_token") || Cookies.get(`pwa_${process.env.RAILS_ENV}_session`);

/**
 * Create a generic API instance with request interceptors
 * @param context - The API context (default, portal, enterprise, etc.)
 * @returns An AxiosInstance with configured interceptors
 */
const createApiInstance = (context: keyof typeof urls = "default"): AxiosInstance => {
  const baseUrl = getRootUrl(context);
  const api = axios.create({ baseURL: baseUrl, withCredentials: false });

  api.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return api;
};

/**
 * Create a Rails-specific API instance without interceptors
 * @returns An AxiosInstance with headers carrying the token
 */
const createRailsApiInstance = (urls): AxiosInstance => {
  const token = getToken();

  return axios.create({
    baseURL: urls,
    withCredentials: true,
  });
};

export { urls, getRootUrl, createApiInstance, createRailsApiInstance };
