// React
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";

// FutureLab
import {
  ApplicationDetails,
  ApplicationForm,
  ApplicationList,
  LegacyLoader,
  PortalAppearance,
  PortalInvitation,
  PortalSettings,
  SessionManagement,
} from "./portal/admin/components/useAdminRoute";
import { PortalProvider, usePortal } from "../contexts/portal";
import { NavbarProvider } from "../contexts/navbarContext";
import DashboardLayout from "./portal/admin/layout/DashboardLayout";
import GenericMsgLoader from "./shared/GenericMsgLoader";
import { UserProvider, useUser } from "../contexts/user";
import Dashboard from "./portal/admin/pages/Dashboard";
import PortalAgenda from "./portal/admin/pages/PortalAgenda";
import PortalResources from "./portal/admin/pages/PortalResources";
import PortalAnalytics from "./portal/admin/pages/PortalAnalytics";

const PortalAdmin = ({
  programSlug,
  enterpriseSlug,
  baseUrl,
  portalId,
  onPortal,
}) => {
  return (
    <Router>
      <Suspense fallback={<GenericMsgLoader center content="loading" />}>
        <PortalProvider
          programSlug={programSlug}
          enterpriseSlug={enterpriseSlug}
        >
          <UserProvider>
            <NavbarProvider
              baseUrl={baseUrl}
              portalId={portalId}
              onPortal={onPortal}
            >
              <Routes>
                <Route
                  path={`/app/${enterpriseSlug}/${programSlug}/admin/*`}
                  element={<DashboardPageLayout />}
                >
                  <Route index element={<Dashboard />} />
                  {/* Program Setting */}
                  <Route path="settings" element={<PortalSettings />} />
                  <Route path="appearance" element={<PortalAppearance />} />
                  <Route path="agenda" element={<PortalAgenda />} />
                  <Route path="resources" element={<PortalResources />} />
                  <Route
                    path="dashboard_settings"
                    element={<LegacyLoader id="dashboard_settings" />}
                  />
                  {/* Users */}
                  <Route
                    path="user_management"
                    element={<LegacyLoader id="user_management" />}
                  />
                  <Route path="applications/*">
                    <Route index element={<ApplicationList />} />
                    <Route path="new" element={<ApplicationForm />} />
                    <Route
                      path="edit/:applicationId"
                      element={<ApplicationForm />}
                    />
                    <Route
                      path="show/:applicationId"
                      element={<ApplicationDetails />}
                    />
                  </Route>
                  <Route path="user-invites" element={<PortalInvitation />} />
                  <Route
                    path="availability"
                    element={<LegacyLoader id={"availability/mentors"} />}
                  />
                  {/* Community */}
                  <Route path="events" element={<LegacyLoader id="events" />} />
                  <Route
                    path="newsfeed"
                    element={<LegacyLoader src="newsfeed" />}
                  />
                  {/* Mentorship */}
                  <Route
                    path="group_and_pairings_management"
                    element={
                      <LegacyLoader
                        group="groups"
                        id="groups_and_pairings_management"
                      />
                    }
                  />
                  <Route
                    path="pairing-health"
                    element={<SessionManagement />}
                  />
                  <Route
                    path="goals"
                    element={<LegacyLoader group="" id="goals" />}
                  />
                  <Route
                    path="bookings/bookings_list"
                    element={<LegacyLoader id="bookings/bookings_list" />}
                  />
                  <Route
                    path="mentorship_ratings"
                    element={<LegacyLoader id="mentorship_ratings" />}
                  />
                  <Route
                    path="chat_management"
                    element={<LegacyLoader id="chat_management" />}
                  />

                  <Route path="analytics" element={<PortalAnalytics />} />
                  <Route
                    path="*"
                    element={<GenericMsgLoader center content="404" />}
                  />
                </Route>
              </Routes>
            </NavbarProvider>
          </UserProvider>
        </PortalProvider>
      </Suspense>
    </Router>
  );
};

const DashboardPageLayout = () => {
  const { program, enterprise } = usePortal();
  const { user } = useUser();

  if (!program && !enterprise && !user)
    return <GenericMsgLoader center content="loading" />;

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default PortalAdmin;
