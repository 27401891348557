import React from "react";
import { ApexOptions } from "apexcharts";
import ApexChart from "./ApexChart";

const PairingHealthTrendsLineChart = ({ data = [], isLoading = false }) => {
  const allCategories = data.map((entry) => entry.month);

  // Display only every 3rd month if there are more than 12 months
  const displayedCategories =
    allCategories.length > 12
      ? allCategories.filter((_, index) => index % 3 === 0)
      : allCategories;

  const series = [
    {
      name: "Positive",
      data: data.map((entry) => entry.positive),
    },
    {
      name: "Neutral",
      data: data.map((entry) => entry.neutral),
    },
    {
      name: "Negative",
      data: data.map((entry) => entry.negative),
    },
  ];

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#00d146", "#e8db2f", "#e62727"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: allCategories,
      labels: {
        formatter: (value) => {
          return displayedCategories.includes(value) ? value : "";
        },
      },
    },
    tooltip: {
      x: {
        // Use the original month for tooltips
        formatter: (value) => allCategories[value],
      },
    },
  };

  return (
    <ApexChart
      options={options}
      series={series}
      type="line"
      height="350"
      isLoading={isLoading}
    />
  );
};

export default PairingHealthTrendsLineChart;
