import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const ConnectionsDonutChart = ({ bookings, chat, eventSignUps }) => {
  const series = [bookings || 0, chat || 0, eventSignUps || 0];
  const options: ApexOptions = {
    labels: ["Bookings", "Chat", "Event Sign-ups"],
    legend: {
      show: true,
      position: "bottom",
      markers: {
        radius: 6,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (_, { seriesIndex, w }) => {
        return w.config.series[seriesIndex];
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={350}
    />
  );
};

export default ConnectionsDonutChart;
