import React from "react";
import { usePortal } from "contexts/portal";
import { useSkillsSummary } from "../hooks/usePortalAnalytics";
import InsightsPanel from "./InsightsPanel";

const SkillsInsightsPanel = ({ userType }) => {
  const { program } = usePortal();
  const { data, isLoading } = useSkillsSummary(program.id, userType);

  return (
    <InsightsPanel
      title="Skills"
      data={data}
      isLoading={isLoading}
      dataKeys={{
        name: "skill_name",
        totalUsers: "users_count",
        percentage: "percentage",
      }}
    />
  );
};

export default SkillsInsightsPanel;
