// React
import React from "react";

// UI
import {
  FaCalendarDay,
  FaCalendarPlus,
  FaClipboardCheck,
  FaStar,
} from "react-icons/fa6";

const NavCards = ({ paths }) => {
  const handleOfflineSessionClick = () => {
    const modal = document.getElementById("log_offline_session_modal");
    if (modal) {
      modal.style.display = "block";
      () => {};
    }
  };

  return (
    <div className="tw-grid tw-grid-cols-4 tw-gap-4 xs:tw-grid-cols-2 xs:tw-gap-2 tw-mb-8">
      <a href={paths.appointment}>
        <div className="tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-py-6">
          <div className="tw-flex tw-justify-center tw-items-center xs:tw-text-sm tw-gap-3 tw-font-medium tw-text-[#696969] sm:tw-flex-col">
            <FaCalendarDay size={20} color="#428ac9" />
            Bookings
          </div>
        </div>
      </a>
      <div
        onClick={handleOfflineSessionClick}
        data-target="#log_offline_session_modal"
        className="open_log_offline_session_modal hover:tw-cursor-pointer tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-py-6"
      >
        <div className="tw-flex tw-justify-center tw-items-center xs:tw-text-sm tw-gap-3 tw-font-medium tw-text-[#696969] sm:tw-flex-col">
          <FaClipboardCheck size={20} color="#428ac9" />
          Offline Sessions
        </div>
      </div>
      <a href={paths.review}>
        <div className="tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-py-6">
          <div className="tw-flex tw-justify-center tw-items-center xs:tw-text-sm tw-gap-3 tw-font-medium tw-text-[#696969] sm:tw-flex-col">
            <FaStar size={20} color="#428ac9" />
            Feedbacks
          </div>
        </div>
      </a>
      <a href={paths.availability}>
        <div className="tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-py-6">
          <div className="tw-flex tw-justify-center tw-items-center xs:tw-text-sm tw-gap-3 tw-font-medium tw-text-[#696969] sm:tw-flex-col">
            <FaCalendarPlus size={20} color="#428ac9" />
            Manage Availability
          </div>
        </div>
      </a>
    </div>
  );
};

export default NavCards;
