import { useState, useEffect } from 'react';

/**
 * Get the current window size category based on industry-standard breakpoints.
 * @returns {string} One of 'sm', 'md', 'lg', 'xl', '2xl'.
 */
function getWindowSize() {
  const windowWidth = window.innerWidth;

  if (windowWidth < 640) {
    return 'sm';
  } else if (windowWidth < 768) {
    return 'md';
  } else if (windowWidth < 1024) {
    return 'lg';
  } else if (windowWidth < 1280) {
    return 'xl';
  } else if (windowWidth < 1536) {
    return '2xl';
  } else {
    return '3xl';
  }
}

/**
 * Custom hook to track and update window size dynamically.
 * @returns {string} The current window size category.
 */
export function useWindowSize() {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    // Debounced resize handler
    let timeoutId = null;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setSize(getWindowSize());
      }, 150); // Set to 150ms
    };

    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
}

export default getWindowSize;
