// React
import React from "react";

// UI
import { AvatarGroup, Avatar, Button } from "rsuite";

const GroupCards = ({ groups, rolePermission, handleBookingClick }) => {
  const getMentorId = (group) => {
    const mentor = group.group_participants.find(
      (participant) => participant.user_type === "mentor"
    );

    return mentor.user_id;
  };

  const handleViewInvitationClick = (group) => {
    const modal = document.getElementById(
      `view_invitation_modal_group_${group.id}`
    );
    if (modal) {
      modal.style.display = "block";
      () => {};
    }
  };

  const handleRejectInvitationClick = (group) => {
    const modal = document.getElementById(`reject_group_${group.id}`);
    if (modal) {
      modal.style.display = "block";
      () => {};
    }
  };

  return (
    <div className="tw-mt-8">
      <h6>Groups</h6>
      <div className="tw-grid tw-gap-6 tw-grid-cols-12 xs:tw-grid-cols-3 sm:tw-grid-cols-6 tw-grid-cols-9">
        {groups?.map((group) => {
          return (
            <div
              key={group.id}
              className="tw-col-span-3 tw-border tw-border-solid tw-rounded-lg tw-border-slate-300 tw-p-4"
            >
              <div>
                <AvatarGroup>
                  {group.group_participants.map((participant) => (
                    <Avatar
                      // bordered
                      // color="blue"
                      circle
                      key={participant.user_id}
                      src={participant.profile_photo}
                      // alt={participant.image_alt_name}
                    />
                  ))}
                </AvatarGroup>
                <h6 className="tw-py-3">{group.name}</h6>
                {group.pending ? (
                  <>
                    {rolePermission.admin || rolePermission.mentor ? (
                      <div className="tw-flex tw-justify-center">
                        <Button
                          className="tw-mr-3"
                          href={group.accept_group_invite_path}
                          appearance="primary"
                        >
                          Join Group
                        </Button>
                        <Button
                          onClick={() => handleRejectInvitationClick(group)}
                          data-target={`#reject_group_${group.id}`}
                          className={`invitation-message group_${group.id} tw-bg-[#D12F1D] tw-text-white`}
                        >
                          Decline
                        </Button>
                        {/* <Button
                    block
                    appearance="ghost"
                    onClick={() => handleViewInvitationClick(group)}
                    data-target={`#view_invitation_modal_group_${group.id}`}
                    className={`invitation-message group_${group.id}`}
                  >
                    View Invitation Message
                  </Button> */}
                      </div>
                    ) : (
                      <>
                        <p className="tw-text-sm tw-text-center">
                          Pending Mentor Accept
                        </p>
                        {/* <Button
                    block
                    appearance="ghost"
                    onClick={() => handleViewInvitationClick(group)}
                    data-target={`#view_invitation_modal_group_${group.id}`}
                    className={`invitation-message group_${group.id}`}
                  >
                    View Invitation Message
                  </Button> */}
                      </>
                    )}
                  </>
                ) : (
                  <div className="tw-flex tw-justify-center">
                    {rolePermission.mentee && (
                      <Button
                        appearance="primary"
                        data-target="#new_bookings_modal"
                        data-mentor-id={getMentorId(group)}
                        className="trigger-bookings-modal modal-trigger tw-mr-3"
                        onClick={handleBookingClick}
                      >
                        Book Session
                      </Button>
                    )}
                    {rolePermission.mentor && (
                      <Button
                        href={group.chat_link}
                        className="tw-mr-3"
                        appearance="primary"
                      >
                        Message
                      </Button>
                    )}
                    <Button appearance="ghost" href={group.portal_group_path}>
                      View Progress
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupCards;
