import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Panel } from "rsuite";

const ResourcesPanel = () => {
  const sampleData = [
    { file_name: "Have not connected to Backend*", downloads: 1 },
    { file_name: "Sample activity data", downloads: 5123 },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Resources</h6>
        <Button
          appearance="ghost"
          onClick={() => navigate("../resources?view=2")}
        >
          View more
        </Button>
      </div>

      <Panel bordered>
        <div className="tw-flex tw-justify-between tw-mb-4">
          <h6>File</h6>
          <h6>Downloads</h6>
        </div>

        {sampleData.length === 0 ? (
          <div>No resources downloaded</div>
        ) : (
          <>
            {sampleData.map(({ file_name, downloads }, index) => (
              <>
                <div
                  key={index}
                  className="tw-flex tw-justify-between tw-mb-4 tw-pb-4"
                  style={{ borderBottom: "1px solid #E5E5E5" }}
                >
                  <div>{file_name}</div>
                  <div>{downloads}</div>
                </div>
              </>
            ))}
          </>
        )}
      </Panel>
    </>
  );
};

export default ResourcesPanel;
