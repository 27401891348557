// React
import React from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import { FutureLabApi } from "../../util/api";
import Toaster from "components/shared/Toaster";
import FieldError from "components/form/FieldError";
import { UserNotification } from "./types";
import { notificationSchema } from "./schema";

// UI
import { Button, Form, Input, Toggle } from "rsuite";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

const Notifications = () => {
  const { user, setUser } = useOutletContext() as any;

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UserNotification>({
    resolver: yupResolver(notificationSchema),
    defaultValues: {
      user: {
        phone_no: user.phone_no,
        whatsapp_notification: user.whatsapp_notification,
      },
    },
  });

  const onSubmit = (data) => {
    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        setUser(data);
        Toaster({
          type: "success",
          msg: "Notification setting successfully updated",
        });
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <Form>
      <Form.Group className="tw-flex tw-justify-between tw-items-center">
        <Form.ControlLabel className="tw-text-sm tw-font-medium tw-mb-0">
          Phone number
        </Form.ControlLabel>
        <Controller
          name="user.phone_no"
          control={control}
          render={({ field }) => (
            <Input
              type=""
              placeholder="Phone number"
              size="lg"
              style={{ width: 200 }}
              {...field}
            />
          )}
        />
        <ErrorMessage errors={errors} name="user.phone_no" as={FieldError} />
      </Form.Group>
      <Form.Group className="tw-flex tw-justify-between">
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          Whatsapp notification
          <Form.HelpText tooltip>
            Enable notifications to stay updated and never miss important
            messages or alerts
          </Form.HelpText>
        </Form.ControlLabel>
        <Controller
          name="user.whatsapp_notification"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Toggle
              checked={value}
              onChange={onChange}
              checkedChildren="Enable"
              unCheckedChildren="Disable"
              size="lg"
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="user.whatsapp_notification"
          as={FieldError}
        />
      </Form.Group>
      <div className="tw-text-right">
        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default Notifications;
