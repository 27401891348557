// React
import React, { useCallback } from "react";

// FutureLab
import { useUser } from "../../contexts/user";
import { FutureLabApi } from "../../util/api";
import { CollegeInput } from "./types";
import {
  FormDatePicker,
  FormInputPicker,
  FormInputText,
} from "components/form/default/FormInputs";
import Toaster from "components/shared/Toaster";
import FieldError from "components/form/FieldError";
import { collegeSchema } from "./schema";

// UI
import { Button, Checkbox, Form, InputPicker } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import _, { debounce } from "lodash";

const levelOptions = [
  // { label: "Not specified", value: "not_specified" },
  // { label: "High School Graduate", value: "high_school" },
  // { label: "Pre University Graduate", value: "pre_university" },
  { label: "Diploma", value: "diploma" },
  { label: "Bachelors", value: "bachelors" },
  { label: "Masters", value: "masters" },
  { label: "PhD", value: "phd" },
  { label: "Postdoctorate", value: "postdoctarate" },
  { label: "Others", value: "other" },
];

const CollegeForm = ({
  college,
  universities,
  handleSearch,
  closeCollegeModal,
  loading,
  initializedDeleteCollegeConfimartionModal,
}) => {
  const { user, refreshUser } = useUser();
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<CollegeInput>({
    resolver: yupResolver(collegeSchema),
    defaultValues: {
      user_id: user.id,
      program_name: college?.program_name || "",
      university_name: college?.university?.name || "",
      level: college?.level || "",
      start_date: college?.start_date && new Date(college?.start_date),
      end_date: college?.end_date && new Date(college?.end_date),
      study_status: college?.study_status || 0,
      // cgpa: college?.cgpa || "",
    },
  });
  const watchStudyStatus = watch("study_status");
  const watchStartDate = watch("start_date");

  const debouncedFetchUniversities = useCallback(
    debounce((searchKeyword) => {
      if (searchKeyword) {
        handleSearch(searchKeyword);
      }
    }, 300), // Adjust debounce delay as needed
    []
  );

  const onSubmit = (data) => {
    FutureLabApi({
      method: college ? "patch" : "post",
      path: college ? `/colleges/${college.id}` : `/colleges`,
      options: data,
      success: ({ data }) => {
        refreshUser();
        closeCollegeModal();
        Toaster({
          type: "success",
          msg: "Education successfully saved",
        });
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <Form fluid className="tw-pt-4">
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          University
        </Form.ControlLabel>
        <Controller
          name="university_name"
          control={control}
          render={({ field }) => (
            <InputPicker
              {...field}
              block
              creatable
              size="lg"
              data={universities}
              placeholder="Search"
              valueKey="name"
              onSearch={debouncedFetchUniversities}
              searchBy={() => true}
              renderMenu={(menu) => {
                if (loading) {
                  return (
                    <p
                      style={{
                        color: "#999",
                        textAlign: "center",
                      }}
                    >
                      <SpinnerIcon spin /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
            />
          )}
        />
        <ErrorMessage errors={errors} name="university_name" as={FieldError} />
      </Form.Group>
      <Form.Group>
        <FormInputPicker
          data={levelOptions}
          block
          size="lg"
          control={control}
          errors={errors}
          name="level"
          label="Qualification"
          placeholder="Bachelors"
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          control={control}
          errors={errors}
          name="program_name"
          label="Major"
          placeholder="Data Science"
          size="lg"
        />
      </Form.Group>
      <div className="tw-flex tw-gap-2">
        <Form.Group className="tw-flex-1 tw-mb-0">
          <FormDatePicker
            control={control}
            errors={errors}
            name="start_date"
            label="Start date"
            placeholder="Select date"
            oneTap
            editable={true}
            format="MMM yyyy"
            size="lg"
          />
        </Form.Group>
        <Form.Group className="tw-flex-1 tw-mb-0">
          <FormDatePicker
            shouldDisableDate={(date) =>
              DateTime.fromJSDate(date) < DateTime.fromJSDate(watchStartDate)
            }
            control={control}
            errors={errors}
            name="end_date"
            label="End date"
            placeholder="Select date"
            oneTap
            editable={true}
            disabled={watchStudyStatus === 1}
            format="MMM yyyy"
            size="lg"
          />
        </Form.Group>
      </div>
      <Form.Group>
        <Controller
          name="study_status"
          control={control}
          render={({ field }) => (
            <Checkbox
              name="study_status"
              defaultChecked={field.value === 0 ? false : true}
              onChange={(value, checked) => {
                setValue(field.name, checked ? 1 : 0);
              }}
            >
              <span style={{ fontSize: 14 }}>I'm currently studying here</span>
            </Checkbox>
          )}
        />
        <ErrorMessage errors={errors} name="study_status" as={FieldError} />
      </Form.Group>

      <div className="tw-text-right">
        {college && (
          <Button
            appearance="link"
            className="tw-text-[#d12f1d]"
            onClick={initializedDeleteCollegeConfimartionModal}
          >
            Remove
          </Button>
        )}
        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default CollegeForm;
