import React from "react";
import { Col, FlexboxGrid } from "rsuite";
import SessionCompletionPanel from "./portal/admin/components/analytics/components/SessionsCompletionPanel";
import SessionsPanel from "./portal/admin/components/analytics/components/PairingPanel";
import LearningExperiencePanel from "./portal/admin/components/analytics/components/LearningExperiencePanel";
import TotalSessionsPanel from "./portal/admin/components/analytics/components/TotalSessionsPanel";
import TotalUsersPanel from "./portal/admin/components/analytics/components/TotalUsersPanel";

const PortalAnalytics = (props) => {
  const {
    programName,
    progId,
    pairsCount,
    groupsCount,
    totalSessions,
    avgSessionsConducted,
    avgRatingsConducted,
    totalUsers,
    totalMentees,
    totalMentors,
    totalAdmins,
    avgMenteeRating,
    avgMentorRating,
  } = props;

  return (
    <FlexboxGrid justify="center">
      <div className="tw-w-full tw-mb-4">
        <h1 className="tw-text-xl">Overview</h1>
      </div>

      {/* Top section */}
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        sm={12}
        lg={24}
        className="tw-w-full tw-flex tw-flex-row"
      >
        <div className="tw-w-1/3">
          <SessionsPanel pairsCount={pairsCount} groupsCount={groupsCount} />
          <LearningExperiencePanel
            avgMenteeRating={avgMenteeRating}
            avgMentorRating={avgMentorRating}
          />
        </div>

        {/* Booking */}
        <TotalSessionsPanel
          totalSessions={totalSessions}
          avgSessionsConducted={avgSessionsConducted}
          avgRatingsConducted={avgRatingsConducted}
        />

        {/* Users */}
        <TotalUsersPanel
          totalMentees={totalMentees}
          totalMentors={totalMentors}
          totalAdmins={totalAdmins}
          totalUsers={totalUsers}
        />
      </FlexboxGrid.Item>

      <SessionCompletionPanel progId={progId} />
    </FlexboxGrid>
  );
};

export default PortalAnalytics;
