import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import PairingHealthTrendsLineChart from "../charts/PairingHealthTrendsLineChart.tsx";
import { usePairingHealthSummary } from "../hooks/usePortalAnalytics";
import { usePortal } from "contexts/portal";

const PairingHealthTrendsPanel = ({ type }) => {
  const { program } = usePortal();
  const { data, isLoading } = usePairingHealthSummary(program.id, type);
  const navigate = useNavigate();

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Pairing Health Trends</h6>
        <Button
          appearance="ghost"
          onClick={() => navigate("../pairing-health")}
        >
          View Details
        </Button>
      </div>
      <PairingHealthTrendsLineChart data={data} isLoading={isLoading} />
    </>
  );
};

export default PairingHealthTrendsPanel;
