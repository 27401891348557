import React from "react";
import { Button, Panel } from "rsuite";
import SurveyDonutChart from "../charts/SurveyDonutChart";
import { useSurveySummary } from "../hooks/usePortalAnalytics";
import { usePortal } from "contexts/portal";
import useAdminRoute from "../../useAdminRoute";
import { useNavigate } from "react-router-dom";

const SurveyPanel = () => {
  const { program } = usePortal();

  const { data, isLoading, isError, error } = useSurveySummary(program?.id);

  const {
    completed_survey_count: completedCount,
    ongoing_survey_count: onGoingCount,
    survey_sent_count: sentSurveyCount,
    respond_rate: respondRate,
  } = data || {};

  const { surveyUrl } = useAdminRoute();

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Survey</h6>
        <Button
          appearance="ghost"
          onClick={() => (window.location.href = surveyUrl)}
        >
          View more
        </Button>
      </div>

      <Panel bordered>
        <div className=" tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-flex tw-flex-col tw-items-center tw-mb-2">
            <span className="tw-text-3xl tw-font-bold">{respondRate}%</span>
            <span className="tw-text-sm">Response rate</span>
          </div>

          <div className="tw-relative">
            <SurveyDonutChart
              completed={completedCount}
              ongoing={onGoingCount}
            />

            <div
              className="tw-flex tw-justify-center tw-flex-col tw-text-center"
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <span className="tw-font-bold">{sentSurveyCount}</span>
              <span className="tw-text-sm">Surveys sent</span>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default SurveyPanel;
