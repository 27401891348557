import React from "react";

import {
  FormDatePicker,
  FormInputText,
} from "components/form/default/FormInputs";
import { Checkbox, Divider, Form } from "rsuite";

import { Controller, useFormContext, useWatch } from "react-hook-form";

const AgendaForm = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const isExistingAgenda = useWatch({ control, name: "id" });
  const isPublishedAgenda = useWatch({ control, name: "published" });
  
  return (
    <Form>
      <Form.Group className="tw-w-1/2">
        {/* Program Start Time */}
        <FormDatePicker
          name="start_time"
          label={"Start Date and Time"}
          control={control}
          errors={errors}
          showMeridian
          format={"dd MMM yyyy - h:mm a"}
          placeholder={"End Date and Time"}
          placement="autoVerticalStart"
        />
      </Form.Group>
      <Form.Group className="tw-w-1/2">
        {/* Program End Time */}
        <FormDatePicker
          name="end_time"
          label={"End Date and Time"}
          control={control}
          errors={errors}
          showMeridian
          format={"dd MMM yyyy - h:mm a"}
          placeholder={"End Date and Time"}
          placement="autoVerticalStart"
        />
      </Form.Group>

      <Form.Group>
        <FormInputText
          name="title"
          label={"Title"}
          placeholder={"Agenda title"}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="description"
          label={"Description"}
          placeholder={"Agenda description"}
          as="textarea"
          rows={5}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Divider />
      <Form.Group>
        <FormInputText
          name="link"
          label={"Link URL"}
          placeholder={"https://example.com"}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="link_name"
          label={"Link name"}
          placeholder={"e.g. Click here"}
          control={control}
          errors={errors}
        />
      </Form.Group>
      {!!isExistingAgenda && isPublishedAgenda && (
        <Form.Group>
          <Controller
            name="sent_updated_agenda"
            control={control}
            render={({ field }) => (
              <Checkbox
              name="sent_updated_agenda"
              onChange={(value, checked) => {
                setValue(field.name, checked);
              }}
            >
              <span style={{ fontSize: 14 }}>Email Updated Agenda</span>
            </Checkbox>
            )}
          />
        </Form.Group>
      )}
    </Form>
  );
};

export default AgendaForm;
