// React
import React, { useLayoutEffect, useState } from "react";
import { useSetState } from "react-use";
import { useOutletContext } from "react-router-dom";

// FutureLab
import SimilarMentors from "./component/futurelab/SimilarMentors";
import ActionDrawer from "../shared/ActionDrawer";
import { useUser } from "../../../contexts/user";
import getWindowSize from "../../../util/getWindowSize";
import { PlatformType } from "../../../contexts/mentor_profile";
import UserProfileHeader from "../shared/UserProfileHeader";
import MentorProfileBody from "./component/shared/MentorProfileBody";

// 3rd Party
import Joyride, { EVENTS } from "react-joyride";
import MobileFooterButton from "../shared/MobileFooterButton";

const TooltipContent = () => (
  <div className="tw-flex tw-flex-col tw-py-1 tw-gap-2">
    <span className="tw-font-bold tw-m-0 tw-p-0 tw-text-left">
      You can now follow your favourite Mentors!
    </span>
    <p className="tw-m-0 tw-p-0">
      Stay connected and never miss their upcoming events
    </p>
  </div>
);

const MentorProfileContent = ({ convertText, showSurvey }) => {
  const { mentor, type } = useOutletContext<any>();
  const { user: currentUser } = useUser();
  const [openBookingDrawer, setOpenBookingDrawer] = useState<boolean>(false);
  const [run, setRun] = useSetState<boolean | any>(false);
  const [steps, setSteps] = useSetState<any[]>([
    {
      target: ".mentor_follow_toggle",
      content: <TooltipContent />,
      disableBeacon: true,
      placement: "bottom-end",
    },
  ]);

  const isLoggedIn = !!currentUser?.id || null;

  useLayoutEffect(() => {
    //get localstorage
    const mentorProfileTour = localStorage.getItem("mentorProfileTour") || null;
    const parsed = mentorProfileTour ? JSON.parse(mentorProfileTour) : null;
    if (
      isLoggedIn &&
      (!parsed?.mentorProfileTour || parsed?.userId !== currentUser.id)
    ) {
      setRun((prev) => !prev);
    }
  }, []);

  const handleTourEnd = (data) => {
    const tourEnd = EVENTS.TOUR_END;
    if (tourEnd.includes(data.type)) {
      //save local storage
      localStorage.setItem(
        "mentorProfileTour",
        JSON.stringify({
          mentorProfileTour: true,
          userId: currentUser?.id,
        })
      );
    }
  };

  const mediaWindowSize = getWindowSize();

  const joyrideRender: JSX.Element = (
    //@ts-ignore-next-line
    <Joyride
      steps={steps}
      disableScrolling={mediaWindowSize !== "sm"}
      scrollToFirstStep={mediaWindowSize === "sm"}
      run={run}
      callback={handleTourEnd}
      styles={{
        options: {
          arrowColor: "#428AC9",
          primaryColor: "#428AC9",
          textColor: "#222222",
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: "#428AC9",
          border: "solid 1px #428AC9",
        },
      }}
    />
  );

  return (
    <>
      {joyrideRender}
      <UserProfileHeader
        convertText={convertText}
        user={mentor}
        currentUser={currentUser}
        isLoggedIn={isLoggedIn}
        type={type}
      />
      <MentorProfileBody convertText={convertText} currentUser={currentUser} />
      {type === PlatformType.FutureLab && (
        <SimilarMentors convertText={convertText} mentor={mentor} />
      )}
      <div className="tw-hidden sm:tw-block">
        {currentUser?.id !== mentor?.id && (
          <MobileFooterButton
            convertText={convertText}
            setOpenBookingDrawer={setOpenBookingDrawer}
            user={mentor}
          />
        )}
      </div>
      {openBookingDrawer && (
        <ActionDrawer
          convertText={convertText}
          setOpenBookingDrawer={setOpenBookingDrawer}
          openBookingDrawer={openBookingDrawer}
          user={mentor}
        />
      )}
    </>
  );
};

export default MentorProfileContent;
