import React, { useEffect, useState } from "react";
import { Col, FlexboxGrid, Nav } from "rsuite";

import ProgramOverview from "components/portal/admin/components/analytics/pages/ProgramOverview";
import UserEngagement from "components/portal/admin/components/analytics/pages/UserEngagement";
import MentorMenteeHealth from "components/portal/admin/components/analytics/pages/MentorMenteeHealth";
import LearningProgress from "../components/analytics/pages/LearningProgress";
import UserInsights from "../components/analytics/pages/UserInsights";

import { useSearchParams } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

const PortalAnalytics = () => {
  const [activeKey, setActiveKey] = useState("overview");

  const displayComponent = (key) => {
    const componentList = {
      overview: <ProgramOverview setActiveKey={setActiveKey} />,
      engagement: <UserEngagement />,
      health: <MentorMenteeHealth />,
      progress: <LearningProgress />,
      insights: <UserInsights />,
    };

    return componentList[key];
  };

  // Check if query-params 'view' exits and switch to it.
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("view")) setActiveKey(searchParams.get("view"));
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item as={Col} colspan={24} md={18} lg={24}>
            <h1 className="tw-text-xl">Analytics</h1>
            {/* <p className="tw-text-sm">Last updated, 3 days ago.</p> */}

            {/* Nav */}
            <Nav
              appearance="subtle"
              justified
              onSelect={(event) => setActiveKey(event)}
              activeKey={activeKey}
            >
              <Nav.Item eventKey="overview">Program Overview</Nav.Item>
              <Nav.Item eventKey="engagement">User Engagement</Nav.Item>
              <Nav.Item eventKey="health">Mentor-Mentee Health</Nav.Item>
              <Nav.Item eventKey="progress">Learning Progress</Nav.Item>
              <Nav.Item eventKey="insights">User Insights</Nav.Item>
            </Nav>

            {/* Content */}
            <div className="tw-my-10">{displayComponent(activeKey)}</div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </QueryClientProvider>
    </>
  );
};

export default PortalAnalytics;
