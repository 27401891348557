import React from "react";
import { ApexOptions } from "apexcharts";
import ApexChart from "./ApexChart";

const UserSatisfactionDonutChart = ({ data, isLoading }) => {
  const { satisfied, neutral, dissatisfied } = data || {};

  const series = [satisfied || 0, neutral || 0, dissatisfied || 0];
  const options: ApexOptions = {
    labels: ["Happy", "Neutral", "Dissatisfied"],
    legend: {
      show: true,
      position: "bottom",
      markers: {
        radius: 6,
      },
    },
    colors: ["#00d146", "#e8db22", "#e62727"],
  };

  return (
    <ApexChart
      options={options}
      series={series}
      type="donut"
      isLoading={isLoading}
      height="300px"
    />
  );
};

export default UserSatisfactionDonutChart;
