import React from "react";
import { Avatar, Divider, Panel } from "rsuite";
import { useLatestReflectionsSummary } from "../hooks/usePortalAnalytics";
import { usePortal } from "contexts/portal";
import moment from "moment";
import { date } from "yup";
import GenericMsgLoader from "components/shared/GenericMsgLoader";

const LatestReflectionsPanel = ({ userType }) => {
  const { program } = usePortal();
  const { data, isLoading, isError, error } = useLatestReflectionsSummary(
    program.id,
    userType
  );

  const reflections = data || [];

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Latest Reflections</h6>
        {/* TODO: FUT-1611: Disable view details until view has been rectified */}
        {/* <Button appearance="ghost">View more</Button> */}
      </div>
      <Panel bordered className="tw-py-4 tw-min-h-[380px] sm:tw-min-h-1">
        {/* Reflections Card */}

        {isLoading ? (
          <GenericMsgLoader />
        ) : reflections.length > 0 ? (
          reflections.map(
            (
              {
                text,
                rater_name: raterName,
                rater_user_type: raterUserType,
                rated_name: ratedName,
                profile_photo: photo,
                created_at: date,
              },
              index
            ) => (
              <div key={index}>
                <div className="tw-flex">
                  <Avatar
                    src={photo || ""}
                    alt={raterName.charAt(0) || ""}
                    className="tw-mr-2"
                  />
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-text-sm tw-font-bold">
                      {raterUserType}
                    </span>
                    <span className="tw-text-sm">{raterName || "Unknown"}</span>
                  </div>
                </div>

                {/* Review Paragraph */}
                <p className="tw-my-4 tw-text-sm">
                  {text || "No reflection provided."}
                </p>

                <div className="tw-flex tw-justify-between tw-items-center">
                  <span className="tw-text-sm">
                    Review submitted on {moment(date).format("DD MMM YYYY")} for{" "}
                    {ratedName || "Deleted User"}
                  </span>

                  {/* TODO: FUT-1611: Disable view details until view has been rectified */}
                  {/* <Button appearance="link">View details</Button> */}
                </div>

                {index < reflections.length - 1 && <Divider />}
              </div>
            )
          )
        ) : (
          <div className="tw-text-center">No data available.</div>
        )}
      </Panel>
    </>
  );
};

export default LatestReflectionsPanel;
