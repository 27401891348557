// React
import React from "react";

// FutureLab
import avatarPlaceholder from "../../../../images/avatar_placeholder.png";

// UI
import { FaStar } from "react-icons/fa6";
import {
  MdOutlineBusiness,
  MdOutlineBusinessCenter,
  MdOutlineDesignServices,
  MdOutlineLocationOn,
} from "react-icons/md";
import { Button, FlexboxGrid, Stack, Whisper, Tooltip } from "rsuite";

const MentorCards = ({ mentors, program, paths, handleBookingClick }) => {
  return (
    <div className="tw-mt-8">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <h6 className="tw-m-0">Mentors</h6>
        {program.pairing_method == "mentee_picks" && (
          <Button href={paths.portal_directory} appearance="primary">
            View All
          </Button>
        )}
      </div>
      <Stack
        className="tw-overflow-x-auto no-scrollbar"
        wrap
        justifyContent="center"
        alignItems="stretch"
      >
        {mentors?.map((mentor) => {
          return (
            <Stack.Item key={mentor.id} className="user-wrapper">
              <div>
                <div className="tw-p-3 tw-relative">
                  <div
                    className="tw-relative"
                    style={{ borderRadius: "10px", overflow: "hidden" }}
                  >
                    <img
                      src={mentor.profile_photo || avatarPlaceholder}
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = avatarPlaceholder;
                      }}
                    />
                    {mentor?.fl_average_rating >= 4 && (
                      <span
                        className="tw-font-bold tw-ml-4 sm:tw-block sm:tw-ml-0 profile-image-tag"
                        style={{ color: "#FFF" }}
                      >
                        <div className="tw-flex">
                          <FaStar
                            size={20}
                            color="#FFC757"
                            className="tw-mr-1"
                          />

                          <span className="tw-text-sm">
                            {`${mentor.fl_average_rating.toFixed(0)}.0`}
                          </span>
                        </div>
                      </span>
                    )}
                    {mentor?.top_badges?.length > 0 && (
                      <div className="profile-badges-tag tw-w-full tw-flex tw-justify-between tw-items-center">
                        <span className="tw-text-sm tw-font-bold">
                          Top badges
                        </span>
                        <span>
                          {mentor.top_badges.map((badge, idx) => (
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>{badge.name}</Tooltip>}
                              key={idx}
                            >
                              <img
                                key={idx}
                                src={badge.image}
                                alt=""
                                className="tw-mr-1"
                                style={{ height: 23 }}
                              />
                            </Whisper>
                          ))}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tw-w-full tw-px-4 tw-py-2">
                  <div className="tw-mb-3">
                    <a href={mentor.program_member_path}>
                      <h6 className="tw-text-base  tw-m-0 tw-truncate">
                        {mentor.full_name}
                      </h6>
                    </a>
                  </div>
                  <div className="xs:tw-text-sm tw-h-48">
                    <div className="tw-flex tw-my-3">
                      <MdOutlineBusinessCenter
                        size={22}
                        className="tw-mr-2 tw-overflow-visible"
                      />

                      <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                        {mentor?.info?.job_title}
                      </span>
                    </div>
                    <div className="tw-flex tw-my-3">
                      <MdOutlineBusiness
                        size={22}
                        className="tw-mr-2 tw-overflow-visible"
                      />

                      <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                        {mentor?.info?.company}
                      </span>
                    </div>
                    <div className="tw-flex tw-my-3">
                      <MdOutlineDesignServices
                        size={22}
                        className="tw-mr-2 tw-overflow-visible"
                      />

                      <span className="tw-line-clamp-2 tw-text-sm tw-font-medium">
                        {mentor?.goals?.map((goal, index) => (
                          <span key={goal.id}>
                            <span>{goal?.name}</span>
                            {index < mentor.goals.length - 1 && <span>, </span>}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className="tw-flex tw-my-3">
                      <MdOutlineLocationOn
                        size={22}
                        className="tw-mr-2 tw-overflow-visible"
                      />

                      <span className="tw-truncate tw-text-sm tw-font-medium">
                        {mentor?.country?.name}
                      </span>
                    </div>
                  </div>

                  <FlexboxGrid.Item
                    className=" tw-pt-3"
                    style={{ borderTop: "1px solid #E5E5EA" }}
                  >
                    <Button
                      appearance="primary"
                      data-target="#new_bookings_modal"
                      data-mentor-id={mentor.id}
                      block
                      className="trigger-bookings-modal modal-trigger"
                      onClick={handleBookingClick}
                    >
                      Book Session
                    </Button>
                    {mentor.have_booked && (
                      <Button
                        appearance="ghost"
                        block
                        href={mentor.progress_path}
                      >
                        View Progress
                      </Button>
                    )}
                  </FlexboxGrid.Item>
                </div>
              </div>
            </Stack.Item>
          );
        })}
      </Stack>
    </div>
  );
};

export default MentorCards;
