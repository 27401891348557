import React, { useRef, useEffect } from "react";
import { useActivities } from "../hooks/usePortalAnalytics";
import LatestActivityCard from "./LatestActivityCard";
import GenericMsgLoader from "components/shared/GenericMsgLoader";
import { usePortal } from "contexts/portal";

const UserEngagementActivityPanel = ({ date, totalActivity }) => {
  const { program } = usePortal();
  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useActivities(program.id, date);

  const observerRef = useRef();

  // Intersection Observer to trigger fetchNextPage when the user scrolls to the bottom
  useEffect(() => {
    if (!observerRef.current || !hasNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasNextPage, fetchNextPage]);

  const activityData = data ? data.pages.flatMap((page) => page.data) : [];

  return (
    <div>
      <div>
        <h6>{date.format("D MMMM YYYY")}</h6>
        {!isLoading && (
          <p className="tw-text-sm">{totalActivity} User activities</p>
        )}
      </div>

      {isLoading ? (
        <div className="tw-w-full">
          <GenericMsgLoader vertical content="Loading latest activities..." />
        </div>
      ) : activityData.length === 0 ? (
        <div>
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-sm tw-text-gray-600">
              No user engagement data found.
            </p>
          </div>
        </div>
      ) : (
        <>
          {activityData.map((activity, index) => (
            <LatestActivityCard
              key={`${activity?.date}-${index}`}
              mentor={activity?.mentor}
              mentees={activity?.mentees}
              date={activity?.created_at}
              category={activity?.action}
              user={activity?.user}
            />
          ))}
        </>
      )}

      {/* Loading more indicator */}
      <div ref={observerRef} className="tw-mt-4 tw-text-center">
        {isFetchingNextPage && (
          <GenericMsgLoader vertical content="Loading more activities..." />
        )}
      </div>
    </div>
  );
};

export default UserEngagementActivityPanel;
