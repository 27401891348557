import React from "react";
import { usePortal } from "contexts/portal";
import { useGoalsSummary } from "../hooks/usePortalAnalytics";
import InsightsPanel from "./InsightsPanel";

const GoalsInsightsPanel = ({ userType }) => {
  const { program } = usePortal();
  const { data, isLoading } = useGoalsSummary(program.id, userType);

  return (
    <InsightsPanel
      title="Goals"
      data={data}
      isLoading={isLoading}
      dataKeys={{
        name: "goal_name",
        totalUsers: "users_count",
        percentage: "percentage",
      }}
    />
  );
};

export default GoalsInsightsPanel;
