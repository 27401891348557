import React from "react";
import { Button, Panel } from "rsuite";
import { usePairingSummary } from "../hooks/usePortalAnalytics";
import { usePortal } from "contexts/portal";
import { useNavigate } from "react-router-dom";

const PairingPanel = () => {
  const { program } = usePortal();
  const {
    data: pairs,
    isLoading,
    isError,
    error,
  } = usePairingSummary(program?.id);

  const navigate = useNavigate();

  const { pairs_count, groups_count } = pairs || {};

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h6>Pairing</h6>
        <Button
          appearance="ghost"
          onClick={() => navigate("../group_and_pairings_management")}
        >
          View more
        </Button>
      </div>

      <Panel bordered className="tw-py-4">
        <div className="tw-flex tw-justify-items-center">
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-bold">{pairs_count || 0}</span>
            <span className="tw-text-sm">Paired</span>
          </div>
          <div className="tw-flex tw-w-1/2 tw-flex-col tw-text-center">
            <span className="tw-text-3xl tw-font-bold">
              {groups_count || 0}
            </span>
            <span className="tw-text-sm">Group</span>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default PairingPanel;