// React
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import Education from "./Education";
import WorkExperience from "./WorkExperience";
import FieldError from "../ui/FieldError";
import { FutureLabApi } from "util/api";
import Toaster from "components/shared/Toaster";
import { UserExperience } from "./types";
import { experienceSchema } from "./schema";

// UI
import { Button, Divider, Form, TagPicker } from "rsuite";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import _, { map } from "lodash";

// 25/6/23: FUT-1137: Limit the industry selection list
// List is based on production DB IDs
const approvedIndustryList = [
  585, 1186, 231, 3772, 1999, 167, 58, 223, 685, 2079,
];

const Experience = () => {
  const { user, refreshUser } = useOutletContext() as any;
  const [industries, setIndustries] = useState([]);
  const [services, setServices] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserExperience>({
    defaultValues: {
      user_industries_attributes: map(user.industries, (value) => value.id),
      services_attributes: map(user.interested_services, (value) => value.id),
    },
    resolver: yupResolver(experienceSchema),
  });

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/industries",
      success: ({ data }) => {
        let filteredIndustries = data
          .filter((obj) => approvedIndustryList.includes(obj.id))
          .sort((a, b) => a.name.localeCompare(b.name));
        setIndustries(filteredIndustries);
      },
    });

    FutureLabApi({
      method: "get",
      path: "/services",
      success: ({ data }) => {
        setServices(data);
      },
    });
  }, []);

  const onSubmit = (data) => {
    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        refreshUser();
        Toaster({
          type: "success",
          msg: "Experience successfully updated",
        });
      },
      error: (err) => {
        const { error, messages } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <>
      <>
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel className="tw-text-sm tw-font-medium">
              Which job roles best match your experience or background?
            </Form.ControlLabel>
            <Controller
              name="user_industries_attributes"
              control={control}
              render={({ field }) => (
                <TagPicker
                  // disabledItemValues={
                  //   field.value?.length === 3
                  //     ? [...industries]
                  //         .map((obj) => obj.id)
                  //         .filter((id) => !field.value.includes(id))
                  //     : []
                  // }
                  data={industries}
                  searchable={field.value?.length < 3}
                  block
                  placeholder="Please select"
                  size="lg"
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="user_industries_attributes"
              as={FieldError}
            />
          </Form.Group>
          {user.user_type === "mentor" && (
            <Form.Group>
              <Form.ControlLabel className="tw-text-sm tw-font-medium">
                Which services would you like to provide to mentees?
              </Form.ControlLabel>
              <Controller
                name="services_attributes"
                control={control}
                render={({ field }) => (
                  <TagPicker
                    // disabledItemValues={
                    //   field.value?.length === 3
                    //     ? [...industries]
                    //         .map((obj) => obj.id)
                    //         .filter((id) => !field.value.includes(id))
                    //     : []
                    // }
                    data={services}
                    searchable={field.value?.length < 3}
                    block
                    placeholder="Please select"
                    size="lg"
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="services_attributes"
                as={FieldError}
              />
            </Form.Group>
          )}
          <div className="tw-text-right">
            <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
              Save changes
            </Button>
          </div>
        </Form>
        <Divider />
      </>
      <div className="tw-py-3">
        <WorkExperience />
      </div>
      <div className="tw-pt-3 tw-pb-5">
        <Education />
      </div>
    </>
  );
};

export default Experience;
