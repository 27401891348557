// React
import React from "react";

// FutureLab
import { useUser } from "contexts/user";
import SideNav from "./SideNav";
import useEditRoute from "./useEditRoute";
import MobileNav from "./MobileNav";

// UI
import { Avatar, Divider, FlexboxGrid } from "rsuite";

const previewProfile = (user) => {
  window.location.href = `/${user?.user_type}_profile/${user?.slug}`;
};

const EditPageLayout = ({ children }) => {
  const { user } = useUser();
  const { activeRoute } = useEditRoute();

  return (
    <FlexboxGrid justify="center">
      <div className="tw-max-w-4xl">
        <div className="tw-flex tw-pb-6 tw-px-2">
          <div className="tw-pr-6">
            <Avatar
              onClick={() => previewProfile(user)}
              circle
              size="lg"
              src={user?.profile_photo_url}
              className="hover:tw-cursor-pointer"
            />
          </div>
          <div className="tw-flex tw-flex-col">
            <h1 className="tw-text-xl tw-font-medium tw-mb-1">
              {user?.firstname} <span className="tw-text-[#dbdbde]">/</span>{" "}
              {activeRoute?.label}
            </h1>
            <p className="tw-text-base tw-mb-0">{activeRoute?.description}</p>
          </div>
        </div>
        <div className="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-place-content-center">
          <div className="tw-col-span-3 xs:tw-hidden">
            <div className="tw-flex tw-justify-between ">
              <SideNav />
              <div>
                <Divider vertical />
              </div>
            </div>
          </div>
          <div className="tw-hidden xs:tw-col-span-12 xs:tw-block xs:tw-mb-6">
            <MobileNav />
          </div>
          <div className="tw-col-span-9 xs:tw-col-span-12 xs:tw-w-auto sm:tw-w-[500px] tw-w-[600px]">
            {children}
          </div>
        </div>
      </div>
    </FlexboxGrid>
  );
};

export default EditPageLayout;
