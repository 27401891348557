import React from "react";
import LatestActivityCard from "./LatestActivityCard";
import { usePortal } from "contexts/portal";
import { useLatestActivities } from "../hooks/usePortalAnalytics";
import GenericMsgLoader from "components/shared/GenericMsgLoader";
import { Panel } from "rsuite";
import { useWindowSize } from "util/getWindowSize";

const reshapeLatestActivities = (data) => {
  if (!data || !data.data) return [];

  return data.data.map((activity) => ({
    mentor: activity.mentor,
    mentees: activity.mentees,
    created_at: activity.created_at,
    category: activity.action,
    user: activity.user || {},
  }));
};

const LatestActivityPanel = () => {
  const { program } = usePortal();
  const { data, isLoading, isError, error } = useLatestActivities(program?.id);

  // TODO: Update tailwind breakpoints to include HDScreenSize
  const windowSize = useWindowSize();
  const hdDesktopScreen = windowSize === "3xl";

  if (isError) {
    console.error("Error fetching latest activities:", error);
    return <div>Error loading activities</div>;
  }

  const reshapedActivities = reshapeLatestActivities(data);

  return (
    <>
      {isLoading ? (
        <Panel
          bordered
          className={`${
            hdDesktopScreen ? "tw-col-span-4" : "tw-col-span-3"
          } tw-flex tw-items-center tw-justify-center tw-min-h-[300px] sm:tw-min-h-[100px]`}
        >
          <GenericMsgLoader vertical content="Loading latest activities..." />
        </Panel>
      ) : reshapedActivities.length === 0 ? (
        <div>No activities</div>
      ) : (
        <>
          {reshapedActivities
            .slice(0, hdDesktopScreen ? 4 : 3)
            .map((activity, idx) => (
              <LatestActivityCard
                key={idx}
                mentor={activity?.mentor}
                mentees={activity?.mentees}
                date={activity?.created_at}
                category={activity?.category}
                user={activity?.user}
              />
            ))}
        </>
      )}
    </>
  );
};

export default LatestActivityPanel;
