// React
import React, { useEffect, useState } from "react";

// FutureLab
import avatarPlaceholder from "../../../images/avatar_placeholder.png";
import { useUser } from "../../../contexts/user";
import toSentenceCase from "../../../util/toSentenceCase";
import usePrograms from "../../../hooks/usePrograms";
import DashboardCredits from "../../dashboard/DashboardCredits";
import MenuItem from "../common/MenuItem";
import { FutureLabApi } from "../../../util/api";

// UI
import { Button, Loader } from "rsuite";
import {
  MdOutlineInfo,
  MdOutlineOutput,
  MdOutlinePayment,
  MdOutlineSensorOccupied,
  MdOutlineSwitchAccessShortcut,
  MdSettingsBackupRestore,
} from "react-icons/md";
import { useNavbarContext } from "../../../contexts/navbarContext";

const NavProfileMenu = (props) => {
  const { toggleSwitcher } = props;
  const [programRole, setProgramRole] = useState();
  const { user } = useUser();
  const { activeProgram } = usePrograms();
  const { baseUrl, onPortal } = useNavbarContext();

  useEffect(() => {
    if (onPortal) {
      FutureLabApi({
        method: "get",
        path: "/users/current/user_type",
        options: { program_id: activeProgram.id },
        success: ({ data }) => {
          setProgramRole(data);
        },
      });
    }
  }, []);

  const handleBecomeMentor = () =>
    (window.location.href = `/users/${user.id}/mentor_details`);

  if (!user) return <Loader content="Loading" center />;

  return (
    <div className="tw-w-full tw-max-w-full tw-text-[#222222] tw-py-4">
      <div className="tw-flex tw-pb-2 tw-mb-2">
        <div className="tw-px-3 tw-flex tw-gap-3">
          <img
            src={user.profile_photo_url || avatarPlaceholder}
            className="tw-rounded-xl"
            style={{ maxHeight: 60 }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = avatarPlaceholder;
            }}
          />
          <div>
            <p className="tw-m-0 tw-font-bold montserrat">{user.full_name}</p>
            <p className="tw-m-0 tw-text-sm montserrat">{user.email}</p>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-py-2 tw-my-2 tw-px-3">
        <span className="tw-text-sm">Account type</span>
        <div className="tw-flex tw-justify-between tw-items-center">
          <span className="tw-font-medium">
            {onPortal
              ? toSentenceCase(programRole)
              : toSentenceCase(user.user_type)}
          </span>
          {user.user_type == "mentee" && <DashboardCredits iconOnly />}
        </div>
      </div>
      {user.user_type == "mentee" && (
        <div className="tw-px-3 tw-py-4">
          <Button
            onClick={handleBecomeMentor}
            appearance="ghost"
            className="tw-w-full"
          >
            Become Futurelab Mentor
          </Button>
        </div>
      )}
      <div
        className="tw-flex tw-justify-between tw-py-4 tw-px-3 tw-cursor-pointer tw-items-center tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-mx-3 tw-mb-3"
        style={{ height: 60 }}
        onClick={toggleSwitcher}
      >
        <div className="tw-flex tw-grow tw-items-center">
          <img
            src={activeProgram.photo_url}
            alt=""
            className="tw-w-8 tw-mr-2 tw-rounded-sm"
          />

          <span className="tw-text-sm tw-font-bold">{activeProgram?.name}</span>
        </div>
        <div
          className="tw-flex tw-flex-col tw-items-center tw-pl-4 tw-cursor-pointer"
          style={{ color: "#428ac9", borderLeft: "1px solid #CCCCCC" }}
        >
          <span style={{ fontSize: 20 }}>
            <MdOutlineSwitchAccessShortcut />
          </span>
          <span className="tw-text-xs">Switch</span>
        </div>
      </div>
      <div>
        <MenuItem
          to={baseUrl.editProfile}
          icon={<MdOutlineSensorOccupied />}
          label="Edit Profile"
        />
        {!onPortal && user.user_type == "mentee" && (
          <MenuItem
            to={baseUrl.subscriptions}
            icon={<MdOutlinePayment />}
            label="Purchase History"
          />
        )}
        {/* Disable Temporarily */}
        {/* <MenuItem
          to={baseUrl.support}
          icon={<MdOutlineInfo />}
          label="Support"
        /> */}
        {baseUrl.admin && (
          <MenuItem
            to={baseUrl.admin}
            icon={<MdSettingsBackupRestore />}
            label="Revert to Admin"
          />
        )}
        <MenuItem
          to={"/users/logout"}
          icon={<MdOutlineOutput />}
          label="Logout"
        />
      </div>
    </div>
  );
};

export default NavProfileMenu;
