import { useQuery, UseQueryOptions, QueryKey } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const useApiQuery = <TData = AxiosResponse, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: Omit<
    UseQueryOptions<TData, TError, TData, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<TData, TError>({
    queryKey,
    queryFn,
    ...options, // Merge other options such as staleTime or cacheTime
  });
};
