// React
import React from "react";

// FutureLab
import GroupCards from "./components/GroupCards";
import BookingCards from "./components/BookingCards";
import MenteesCards from "./components/MenteeCards";
import MentorCards from "./components/MentorCards";
import NavCards from "./components/NavCards";

// 3rdParty
import _ from "lodash";

const PortalDashboard = ({
  rolePermission,
  bookings,
  paths,
  groups,
  mentees,
  mentors,
  program,
}) => {
  const handleBookingClick = () => {
    const modal = document.getElementById("new_bookings_modal");
    if (modal) {
      modal.style.display = "block";
      () => {};
    }
  };

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-max-w-5xl">
        <NavCards paths={paths} />

        {bookings && <BookingCards bookings={bookings} />}

        {!_.isEmpty(groups) && (
          <GroupCards
            groups={groups}
            rolePermission={rolePermission}
            handleBookingClick={handleBookingClick}
          />
        )}

        {rolePermission.mentor && !_.isEmpty(mentees) && (
          <MenteesCards mentees={mentees} />
        )}

        {rolePermission.mentee && !_.isEmpty(mentors) && (
          <MentorCards
            mentors={mentors}
            program={program}
            paths={paths}
            handleBookingClick={handleBookingClick}
          />
        )}
      </div>
    </div>
  );
};

export default PortalDashboard;
